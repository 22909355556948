import {Action, createReducer, on} from '@ngrx/store';
import {Call} from '../model/call';
import * as ManageActions from './manage.actions';


export interface ManageState {
  call?: Call;
}

export const initialState: ManageState = {
  call: undefined
};

const manageReducer0 = createReducer(
  initialState,

  on(ManageActions.manageCallResponse,
    (state, action) => {
      return {
        call: action.call
      };
    })
);


export function manageReducer(state: ManageState, action: Action): ManageState {
  return manageReducer0(state, action);
} // managerReducer

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AccortoService, Logger } from 'accorto';
import { CRequestConfirm } from '../model/c-request-confirm';
import { CResponseConfirm } from '../model/c-response-confirm';
import { Pick4dCUtil } from '../model/pick4d-c-util';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  private log: Logger = new Logger('ConfirmService');

  constructor(private http: HttpClient,
              private config: AccortoService) {
  }

  /**
   * @param request confirm request
   */
  send(request: CRequestConfirm): Observable<CResponseConfirm> {
    this.config.setCRequest(request);

    const url = this.config.server + '/confirmP4d';
    this.log.info('send ' + url, request)();
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseConfirm>(url, body, httpOptions)
      .pipe(
        tap(response => {
          this.log.info('send.response', response)();
        }),
        map(response => Pick4dCUtil.createCResponseConfirm(response))
      );
  } // send

} // ConfirmService

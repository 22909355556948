import { TzInfo } from 'accorto';
import { SelectSlot } from './select-slot';
import { CRequest } from 'accorto';
import { DataRecord } from 'accorto';

/* GenTsClass client: com.pick4d.domain.CRequestConfirm */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: CRequestConfirm
 */
export class CRequestConfirm extends CRequest {

  /**
   * label: Call Type Id
   */
  public callTypeId?: number; // optional=null

  /**
   * label: Call Record
   */
  public record?: DataRecord; // optional=null

  /**
   * label: Slot
   */
  public slot?: SelectSlot; // optional=null

  /**
   * label: Tz Info
   */
  public tzInfo?: TzInfo; // optional=null

  /**
   * label: Call Name (for reschedule)
   */
  public callName?: string; // optional=null

} // CRequestConfirm

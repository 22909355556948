import { Call } from './call';
import { CResponse } from 'accorto';

/* GenTsClass client: com.pick4d.domain.CResponseConfirm */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseConfirm
 */
export class CResponseConfirm extends CResponse {

  public call?: Call; // optional=null

} // CResponseConfirm


/* GenTsClass client: com.pick4d.domain.SelectSlot */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: SelectSlot
 */
export class SelectSlot {

  public callRepId?: number; // optional=null

  public callRepName?: string; // optional=null

  /**
   * local date, e.g. Tue, Jun 1
   * label: Date label
   */
  public dateLabel?: string; // optional=null

  /**
   * Date'T'Time e.g. 2021-06-01T17:00:00.000
   * label: ISO
   */
  public isoDateTime?: string; // optional=null

  /**
   * in user locale e.g. 11:00 AM MDT
   * label: Slot Label
   */
  public label?: string; // optional=null

  public minutes: number = 60;

  /**
   * local name e.g. 11:00
   * label: Slot Name
   */
  public slotName?: string; // optional=null

  /**
   * label: slot start ms
   */
  public startMs: number = NaN; // default

  /**
   * label: Tab index
   */
  public tabIndex?: number; // optional=null

  /**
   * in user locale e.g. 11:00 AM
   * label: Time Label
   */
  public timeLabel?: string; // optional=null

  /**
   * date start in utc
   * label: utc ms
   */
  public utcMs?: number; // optional=null

  /**
   * label: Night time for caller timezone
   */
  public isNightTime: boolean = false;


  /**
   * create clone
   */
  public clone(): SelectSlot {
    const oo = new SelectSlot();
    Object.assign(oo, this);
    return oo;
  } // clone

} // SelectSlot

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccortoService, DataRecord, Logger } from 'accorto';
import { CResponseManage } from '../model/c-response-manage';
import { CRequestManage, Pick4dType } from '../model/c-request-manage';
import { Pick4dCUtil } from '../model/pick4d-c-util';

@Injectable({
  providedIn: 'root'
})
export class ManageService {

  private log: Logger = new Logger('ManageService');

  constructor(private http: HttpClient,
              private config: AccortoService) {
  }

  /**
   * Cancel Call
   * @param callName call name
   * @param cancelMessage message
   */
  cancel(callName: string, cancelMessage: string): Observable<CResponseManage> {
    const request: CRequestManage = new CRequestManage();
    request.callName = callName;
    request.cancelMessage = cancelMessage;
    request.type = Pick4dType.CANCEL;

    return this.send(request);
  }

  /**
   * Query Call by name
   * @param callName call name
   */
  query(callName: string): Observable<CResponseManage> {
    const request: CRequestManage = new CRequestManage();
    request.callName = callName;

    return this.send(request);
  } // query

  /**
   * Search Calls
   * @param contactEmail email
   */
  search(contactEmail: string): Observable<CResponseManage> {
    const request: CRequestManage = new CRequestManage();
    request.contactEmail = contactEmail;

    return this.send(request);
  }

  send(request: CRequestManage): Observable<CResponseManage> {
    this.config.setCRequest(request);

    const url = this.config.server + '/manageP4d';
    this.log.info('send ' + url, request)();
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseManage>(url, body, httpOptions)
      .pipe(
        tap(response => {
          this.log.info('send.response', response)();
        }),
        map(response => Pick4dCUtil.createCResponseManage(response))
      );
  } // send

  /**
   * Update Call
   * @param record call
   */
  update(record: DataRecord): Observable<CResponseManage> {
    const request: CRequestManage = new CRequestManage();
    request.type = Pick4dType.UPDATE;
    request.record = record;

    return this.send(request);
  }

} // ManageService


<div class="day slds-m-vertical_small slds-is-relative slds-grid slds-wrap">
  <div class="slds-col"
       style="min-width: 18rem; min-height: 290px;">
    <acc-date-picker (dateSelected)="onDateSelected($event)"
                     [enabledDays]="enabledDays"
                     [initialDate]="selectedDate"
                     [todayEmits]=true
                     align="stand-alone"></acc-date-picker>
  </div>

  <div class="slds-col">
    <h2 class="slds-text-heading_small slds-m-bottom_small">{{selectedDateString}}</h2>

    <table *ngIf="daySlots.length > 0"
           class="slds-table slds-table_bordered" id="day">
      <tbody>
      <tr *ngFor="let slot of daySlots; index as ix"
          class="slot">
        <td class="slds-text-align_center day-slot"
            id="slot-x-{{ix}}">
          <a (click)="onSelectSlot(slot)"
             *ngIf="slot?.label"
             [id]="slot.startMs"
             [routerLink]="['/confirm', slot.startMs]"
             [tabindex]="0"
             class="slds-button slds-button_neutral slds-m-around_x-small timeSlot">
            <time [attr.datetime]="slot.isoDateTime"
                  style="white-space: nowrap">{{slot.label}}
            </time>
            <span *ngIf="slot.isNightTime" class="slds-icon_container" title="Night Hours">
            <svg aria-hidden="true" class="slds-icon slds-icon-text-default slds-icon_x-small">
              <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom10"></use>
            </svg>
            <span class="slds-assistive-text">Night Hours</span>
          </span>
          </a>
        </td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="daySlots.length === 0">
      <h3 class="slds-text-heading_small" i18n>No Slots available</h3>
      <p i18n>Please select another day</p>
    </div>

  </div>
  <acc-spinner *ngIf="busy"></acc-spinner>
</div>

<div class="p4d-status" id="selectStatus">{{selectStatus}}</div>

import * as SelectActions from './select.actions';
import {SelectSlot} from '../model/select-slot';
import {Action, createReducer, on} from '@ngrx/store';


export interface SelectState {
  status?: string;
  startTimeMs?: number;
  startTime?: Date;
  firstMs?: number;
  first?: Date;
  slots: SelectSlot[];
  slot?: SelectSlot;
}

export const initialState: SelectState = {
  status: undefined,
  startTimeMs: undefined,
  startTime: undefined,
  firstMs: undefined,
  first: undefined,
  slots: [],
  slot: undefined
};


const selectReducer0 = createReducer(
  initialState,

  on(SelectActions.selectRequest,
    (state, action) => {
      const startTimeMs: number = action.startTimeMs;
      return {
        ...state,
        status: 'requested',
        startTimeMs,
        startTime: startTimeMs === 0 ? undefined : new Date(startTimeMs)
      };
    }),

  on(SelectActions.selectResponse,
    (state, action) => {
      return {
        ...state,
        status: 'ok',
        firstMs: action.selection.firstMs,
        first: action.selection.firstMs ? new Date(action.selection.firstMs) : undefined,
        slots: action.selection.slots
      };
    }),

  on(SelectActions.selectError,
    (state, action) => {
      return {
        ...state,
        status: 'error'
      };
    }),

  on(SelectActions.selectUserSlot,
    (state, action) => {
      return {
        ...state,
        slot: action.slot
      };
    })
);


/**
 * Select Reducer
 * @param state old select state
 * @param action select action
 * @return new select state
 */
export function selectReducer(state: SelectState, action: Action): SelectState {
  return selectReducer0(state, action);
} // reducer

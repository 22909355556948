import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

import {
  AccortoService,
  ClientStorage,
  DataPickOption,
  DataRecord,
  DataTable,
  DataType,
  Logger,
  ModelUtil,
  selectTimezone,
  TzInfo,
  TzUtil,
  UiTab
} from 'accorto';
import {AppState} from '../reducers';
import {SelectSlot} from '../model/select-slot';
import {selectSelectSlot} from '../select/select.selectors';
import {Call} from '../model/call';
import {selectCallType} from '../call-type/call-type.selectors';
import {CRequestConfirm} from '../model/c-request-confirm';
import {CallType} from '../model/call-type';
import {ConfirmService} from './confirm.service';
import {CResponseConfirm} from '../model/c-response-confirm';
import {manageCallResponse} from '../manage/manage.actions';
import {selectManageCall} from '../manage/manage.selectors';

@Component({
  selector: 'p4d-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent implements OnInit, OnDestroy {

  static readonly NAME = 'contactName';
  static readonly EMAIL = 'contactEmail';
  static readonly PHONE = 'contactPhone';
  static readonly ANSWER = 'contactAnswer';
  static readonly ADDITIONAL_1 = 'contactAdditional1';
  static readonly ADDITIONAL_2 = 'contactAdditional2';

  /** Timezone */
  tzInfo?: TzInfo;
  /** Slot (select) */
  slot?: SelectSlot;
  /** Old Call (manage) */
  oldCall?: Call;

  callUi: UiTab = new UiTab();
  record: DataRecord = new Call().asDataRecord();
  /** Pre-populated values */
  preFillValues: { [key: string]: string } = {};

  /** Busy loading */
  busy: boolean = true;
  /** Load Status */
  confirmStatus: string = '-';

  confirmError?: string;
  confirmMessage?: string;

  private log: Logger = new Logger('Confirm');

  /** Call Type */
  private callType?: CallType;

  private subscriptions: Subscription[] = [];


  constructor(private store: Store<AppState>,
              private service: ConfirmService,
              private config: AccortoService) {
    // Preference.prefDebug.saveBoolean(false);
  } // constructor

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit(): void {
    // slot
    this.subscriptions.push(
      this.store.pipe(select(selectSelectSlot))
        .subscribe((slot: SelectSlot | undefined) => {
          if (slot) {
            this.log.debug('ngOnInit - slot', slot)();
            this.slot = slot.clone();
            this.updateSlot();
          }
        })
    );

    // timezone
    this.subscriptions.push(
      this.store.pipe(select(selectTimezone))
        .subscribe((tzInfo: TzInfo | undefined) => {
          if (tzInfo) {
            //  this.log.debug('ngOnInit - tzInfo', tzInfo)();
            this.tzInfo = tzInfo;
            this.updateSlot();
          }
        })
    );

    // call
    this.subscriptions.push(
      this.store.pipe(select(selectManageCall))
        .subscribe((call: Call | undefined) => {
          if (call) {
            //  this.log.debug('ngOnInit - oldCall', call)();
            this.oldCall = call;
          }
        })
    );

    // callType -> build UI
    this.subscriptions.push(
      this.store.pipe(select(selectCallType))
        .subscribe((callType) => {
          this.log.debug('ngOnInit - callType', callType)();
          this.callType = callType;
          //
          if (this.callType) {
            this.buildUi();
          }
          //
          this.busy = false;
          this.confirmStatus = 'built';
        })
    );
    /*
     this.store.pipe(select(selectUi('Call'))).subscribe((uiTab) => {
      this.log.debug('ngOnInit - uiTab', uiTab)();
      if (uiTab) {
        this.callUi = uiTab;
      }
    });
     this.store.dispatch(uiRequestAction({ uiName: 'Call' }));
     */
  } // ngOnInit

  /**
   * @param record record to be saved
   */
  saveRecord(record: DataRecord): void {
    const req = new CRequestConfirm();
    req.callTypeId = this.callType?.id as number;
    req.slot = this.slot;
    req.tzInfo = this.tzInfo;
    req.record = record;
    if (this.oldCall) {
      req.callName = this.oldCall.name;
    }
    this.log.info('saveRecord', record, req)();
    //
    this.busy = true;
    //
    this.service.send(req)
      .subscribe((resp: CResponseConfirm) => {
      // this.log.info('saveRecord.response', resp)();

        this.busy = false;
        this.confirmMessage = resp.message;
        this.confirmError = resp.error;
        if (resp.call) {
          this.log.info('saveRecord.response', resp.call)();
          this.store.dispatch(manageCallResponse({call: resp.call}));
        }
      });
    // save
    ClientStorage.set(ConfirmComponent.NAME, this.record.value(ConfirmComponent.NAME) ?? '');
    ClientStorage.set(ConfirmComponent.EMAIL, this.record.value(ConfirmComponent.EMAIL) ?? '');
    ClientStorage.set(ConfirmComponent.PHONE, this.record.value(ConfirmComponent.PHONE) ?? '');
  } // saveRecord

  // update time slot label
  updateSlot(): void {
    if (this.slot?.startMs != null && this.tzInfo) {
      const dd = new Date(this.slot.startMs);
      this.slot.slotName = TzUtil.format(this.tzInfo, dd);
    }
  } // updateSlot

  /**
   * create additional column
   * @param name column name
   * @param label label
   * @param optionInfo optional picklist values
   *  e.g. accorto=Time+Expense|checklist=Checklist|pick4d=Pick 4D|track4d=Track 4D|rollup=Rollup"
   * @param defaultValue optional default value
   */
  additionalColumn(name: string, label: string,
                   optionInfo: string | undefined, defaultValue: string | undefined) {
    const col = ModelUtil.createColumn(name,
      label, optionInfo ? DataType.PICK : DataType.STRING);
    col.length = 255;
    if (optionInfo) {
      col.pickOptionList = [];
      const options = optionInfo.split('|');
      for (const option of options) {
        const parts = option.split('=');
        const op = new DataPickOption();
        op.name = parts[0];
        op.label = parts.length > 1 ? parts[1] : parts[0];
        col.pickOptionList.push(op)
      }
    }
    ModelUtil.addFormField(this.callUi, col);
    if (defaultValue) {
      this.preFillValues[name] = defaultValue;
    }
  } // additionalColumn

  /**
   * Create Ui from CallType
   */
  private buildUi(): void {
    // manual ui
    this.callUi.name = 'call';
    this.callUi.uiId = 'call';
    this.callUi.dataTable = new DataTable();
    this.callUi.dataTable.name = 'call';
    //
    let col = ModelUtil.createColumn(ConfirmComponent.NAME, this.callType?.confirmName, DataType.STRING);
    col.autocomplete = 'name';
    ModelUtil.addFormField(this.callUi, col);
    //
    col = ModelUtil.createColumn(ConfirmComponent.EMAIL, this.callType?.confirmEmail, DataType.EMAIL);
    col.isNullable = false;
    col.autocomplete = 'email';
    ModelUtil.addFormField(this.callUi, col);
    //
    if (this.callType?.confirmPhone) {
      col = ModelUtil.createColumn(ConfirmComponent.PHONE, this.callType.confirmPhone, DataType.PHONE);
      col.autocomplete = 'tel';
      ModelUtil.addFormField(this.callUi, col);
    }
    // pre-fill
    this.preFillValues[ConfirmComponent.NAME] = ClientStorage.get(ConfirmComponent.NAME) ?? '';
    this.preFillValues[ConfirmComponent.EMAIL] = ClientStorage.get(ConfirmComponent.EMAIL) ?? '';
    this.preFillValues[ConfirmComponent.PHONE] = ClientStorage.get(ConfirmComponent.PHONE) ?? '';

    // additional
    if (this.callType?.confirmAdditional1) {
      // additional 1 default from parameter 'source' or url site
      let additional1Default: string | undefined = undefined;
      const source = this.config.getEnv('source');
      const site = this.config.getCurrentUrl('site');
      if (source && source.length > 0) {
        additional1Default = source;
      } else if (site.length > 0) {
        additional1Default = site;
      }
      this.additionalColumn(ConfirmComponent.ADDITIONAL_1,
        this.callType.confirmAdditional1, this.callType.confirmAdditional1Options,
        additional1Default);
      // copy previous
      if (this.oldCall?.contactAdditional1) {
        this.preFillValues[ConfirmComponent.ADDITIONAL_1] = this.oldCall.contactAdditional1;
      }
    }
    if (this.callType?.confirmAdditional2) {
      // additional 2 from parameter 'type'
      const additional2Default = this.config.getEnv('type');
      this.additionalColumn(ConfirmComponent.ADDITIONAL_2,
        this.callType.confirmAdditional2, this.callType.confirmAdditional2Options,
        additional2Default);
      // copy previous
      if (this.oldCall?.contactAdditional2) {
        this.preFillValues[ConfirmComponent.ADDITIONAL_2] = this.oldCall.contactAdditional2;
      }
    }
    // answer
    if (this.callType?.confirmQuestion) {
      col = ModelUtil.createColumn(ConfirmComponent.ANSWER, this.callType.confirmQuestion, DataType.STRING);
      col.length = 255;
      ModelUtil.addFormField(this.callUi, col);
      // copy previous
      if (this.oldCall?.contactAnswer) {
        this.preFillValues[ConfirmComponent.ANSWER] = this.oldCall.contactAnswer;
      }
    }
    this.log.debug('buildUi', this.callUi)();
  } // buildUi

} // ConfirmComponent

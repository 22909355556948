import { createAction, props } from '@ngrx/store';
import { CallType } from '../model/call-type';

export enum CallTypeActionTypes {
  CallTypeRequest = '[CallType] Request',
  CallTypeResponse = '[CallType] Response',
  CallTypeError = '[CallType] Error'
}

export const callTypeRequest = createAction(
  CallTypeActionTypes.CallTypeRequest
);

export const callTypeResponse = createAction(
  CallTypeActionTypes.CallTypeResponse,
  props<{ callType: CallType }>()
);

export const callTypeError = createAction(
  CallTypeActionTypes.CallTypeError,
  props<{ err: any }>()
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { manageCallResponse } from './manage.actions';


@Injectable()
export class ManageEffects {


  manageCallResponse$ = createEffect(() => this.actions$.pipe(
    ofType(manageCallResponse),
    tap((action) => {
      this.router.navigateByUrl('/manage');
    })
    ), { dispatch: false }
  );


  constructor(private actions$: Actions,
              private router: Router) {
  }

}

import {Component} from '@angular/core';

@Component({
  selector: 'p4d-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor() {
  }

}

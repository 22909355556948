import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';
import {GoogleAnalyticsService, Logger, ModelUtil, timezoneRequestAction} from 'accorto';
import {CallType} from './model/call-type';
import {selectCallType} from './call-type/call-type.selectors';
import {AppState} from './reducers';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';

/**
 * Pick4D Application
 * - title/meta
 */
@Component({
  selector: 'p4d-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  callType: CallType = new CallType();
  isSelect = true;
  isConfirm = false;
  isManage = false;
  private readonly LI_ACTIVE = 'slds-path__item slds-is-current slds-is-active';
  selectLi: string = this.LI_ACTIVE;
  private readonly LI_COMPLETE = 'slds-path__item slds-is-complete';
  private readonly LI_INCOMPLETE = 'slds-path__item slds-is-incomplete';
  confirmLi: string = this.LI_INCOMPLETE;
  manageLi: string = this.LI_INCOMPLETE;

  private log: Logger = new Logger('AppComponent');

  constructor(private store: Store<AppState>,
              private router: Router,
              ga: GoogleAnalyticsService,
              private pageTitle: Title,
              private pageMeta: Meta) {

    this.callType.headingTitle = 'Pick 4D';
    this.callType.headingSubtitle = 'loading...';
    this.callType.headingInfo = 'pick your time';
    //
    setTimeout(() => {
      store.dispatch(timezoneRequestAction());
    }, 300);

    this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized)
    ).subscribe((rr) => {
      if (rr instanceof RoutesRecognized) {
        const theTitle = rr.state.root.firstChild?.data.title;
        const theDescription = rr.state.root.firstChild?.data.description;
        const theKeywords = rr.state.root.firstChild?.data.keywords;
        //
        if (theTitle) {
          pageTitle.setTitle(theTitle + ' | Pick 4D (Time)');
        }
        //
        if (theDescription) {
          const description: MetaDefinition = {
            name: 'description',
            content: theDescription
          };
          pageMeta.updateTag(description);
        }
        if (theKeywords) {
          const keywords: MetaDefinition = {
            name: 'keywords',
            content: theKeywords
          };
          pageMeta.updateTag(keywords);
        }
      }
    });
  } // constructor


  // callTypeHeadingTitle$: Observable<String>;

  get isImage(): boolean {
    return this.callType.headingLogo != null && this.callType.headingLogo.length > 0;
  }

  get headingInfo(): string[] {
    return ModelUtil.toSentences(this.callType.headingInfo);
  }

  get title(): string {
    if (this.isSelect) {
      return this.callType.selectTitle;
    } else if (this.isConfirm) {
      return this.callType.confirmTitle;
    } else if (this.isManage) {
      //  return this.callType.manageTitle;
    }
    return '';
  }

  get instructions(): string {
    if (this.isSelect) {
      return this.callType.selectInstructions;
    } else if (this.isConfirm) {
      return this.callType.confirmInstructions;
    } else if (this.isManage) {
      //  return this.callType.manageInstructions;
    }
    return '';
  }

  get footer(): string {
    if (this.isSelect) {
      return this.callType.selectFooter ?? '';
    } else if (this.isConfirm) {
      return this.callType.confirmFooter ?? '';
    } else if (this.isManage) {
      //  return this.callType.manageFooter;
    }
    return '';
  }

  ngOnInit(): void {
    // this.callTypeHeadingTitle$ = this.store.pipe(select(selectCallTypeHeadingTitle));

    this.store.pipe(select(selectCallType)).subscribe((callType) => {
      if (callType) {
        this.log.info('ngOnInit - callType', callType)();
        this.callType = callType;
      }
    });

    //
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/select' || e.urlAfterRedirects === '/select') {
          this.doSelect();
        } else if (e.url.startsWith('/confirm')) {
          this.doConfirm();
        } else if (e.url.startsWith('/manage')) {
          this.doManage();
        } else {
          this.log.info('router NotHandled', e)();
        }
      }
    });

  } // ngOnInit

  // li: slds-is-current slds-is-active - slds-is-complete - slds-is-incomplete
  // a: aria-selected true/false - tabindex 0/-1

  doSelect(): void {
    this.log.debug('doSelect')();
    this.isSelect = true;
    this.isConfirm = false;
    this.isManage = false;
    //
    this.selectLi = this.LI_ACTIVE;
    this.confirmLi = this.LI_INCOMPLETE;
    this.manageLi = this.LI_INCOMPLETE;
  }


  doConfirm(): void {
    this.log.debug('doConfirm')();
    this.isSelect = false;
    this.isConfirm = true;
    this.isManage = false;
    //
    this.selectLi = this.LI_COMPLETE;
    this.confirmLi = this.LI_ACTIVE;
    this.manageLi = this.LI_INCOMPLETE;
  }


  doManage(): void {
    this.log.debug('doManage')();
    this.isSelect = false;
    this.isConfirm = false;
    this.isManage = true;
    //
    this.selectLi = this.LI_COMPLETE;
    this.confirmLi = this.LI_COMPLETE;
    this.manageLi = this.LI_ACTIVE;
  }

} // AppComponent

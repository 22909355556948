import { SelectSlot } from './select-slot';

/* GenTsClass client: com.pick4d.domain.SelectDay */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: SelectDay
 */
export class SelectDay {

  public label?: string; // optional=null

  public slots: SelectSlot[] = [];

} // SelectDay

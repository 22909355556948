import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {Logger, selectTimezone, TzInfo, TzUtil} from "accorto";

import {SelectDay} from "../../model/select-day";
import {SelectSlot} from "../../model/select-slot";
import {selectRequest, selectUserSlot} from "../select.actions";
import {AppState} from "../../reducers";
import {CallType} from "../../model/call-type";
import {selectCallType} from "../../call-type/call-type.selectors";
import {selectSelectSlots, selectSelectStatus} from "../select.selectors";

@Component({
  selector: 'p4d-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WeekComponent implements OnInit, OnDestroy {

  /** Start of the Week */
  startOfTheWeek: Date;
  /** Week Info */
  week: SelectDay[] = [];
  /** Week Slots */
  weekSlots: SelectSlot[][] = [];
  /** Busy loading */
  busy = true;
  /** Status */
  selectStatus: string = '-';

  private readonly ONEDAY: number = 24 * 60 * 60 * 1000;
  private readonly ONEWEEK: number = 7 * 24 * 60 * 60 * 1000;

  /** Call Type */
  private callType?: CallType;
  /** TzInfo */
  private tzInfo?: TzInfo;
  /** Select (all) Slots */
  private slots: SelectSlot[] = [];

  private log: Logger = new Logger('SelectWeek');
  private subscriptions: Subscription[] = [];


  constructor(private store: Store<AppState>) {
    const today = new Date();
    const todayUtc: Date = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    const dayUtc = todayUtc.getUTCDay(); // 0=Sunday
    const delta = dayUtc * this.ONEDAY;
    this.startOfTheWeek = new Date(todayUtc.getTime() - delta);
  }


  get startLabel(): string {
    // const language = navigator.language;
    // Week of ...
    return this.startOfTheWeek.toLocaleDateString();
  }

  /**
   * @return previous button enabled
   */
  get previousEnabled(): boolean {
    const today = new Date();
    const time = this.startOfTheWeek.getTime();
    return time > today.getTime();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit(): void {
    // call type
    this.subscriptions.push(
      this.store.pipe(select(selectCallType))
        .subscribe((callType) => {
          if (callType) {
            //  this.log.debug('ngOnInit - callType', callType)();
            this.callType = callType;
          }
        })
    );

    // time zone
    this.subscriptions.push(
      this.store.pipe(select(selectTimezone))
        .subscribe((tzInfo) => {
          if (tzInfo) {
            //  this.log.debug('ngOnInit - tzInfo', tzInfo)();
            this.tzInfo = tzInfo;
            this.buildWeek();
          }
        })
    );

    // status (busy)
    this.subscriptions.push(
      this.store.pipe(select(selectSelectStatus))
        .subscribe((state) => {
          // this.log.debug('ngOnInit - state', state)();
          this.busy = !state || state === 'requested';
          if (state) {
            this.selectStatus = state;
          }
        })
    );

    this.subscriptions.push(
      this.store.pipe(select(selectSelectSlots))
        .subscribe((slots: SelectSlot[]) => {
          // this.log.debug('ngOnInit - slotList', slots)();
          if (slots) {
            // this.slots = slots; // read-only
            this.slots = [];
            slots.forEach((slot) => {
              const cc = new SelectSlot();
              Object.assign(cc, slot);
              this.slots.push(cc);
            });
            this.buildWeek();
          }
        })
    );
  } // ngOnInit

  onClickNext(): void {
    const time = this.startOfTheWeek.getTime();
    this.startOfTheWeek = new Date(time + this.ONEWEEK);
    if (this.callType?.id) {
      this.store.dispatch(selectRequest({
        callTypeId: this.callType.id,
        startTimeMs: this.startOfTheWeek.getTime(),
        endTimeMs: 0
      }));
    }
  }

  onClickPrevious(): void {
    const today = new Date();
    const time = this.startOfTheWeek.getTime();
    if (this.callType?.id && time > today.getTime()) {
      this.startOfTheWeek = new Date(time - this.ONEWEEK);
      this.store.dispatch(selectRequest({
        callTypeId: this.callType.id,
        startTimeMs: this.startOfTheWeek.getTime(),
        endTimeMs: 0
      }));
    }
  }

  onSelectSlot(slot: SelectSlot): void {
    this.store.dispatch(selectUserSlot({slot}));
  }

  isNightTime(slot: SelectSlot): boolean {
    if (slot.slotName) {
      return slot.slotName > '18:00' || slot.slotName < '07:00';
    }
    return false;
  }

  /**
   * Build Hours array, insert empty for skips
   * @param slotStartSet set with start times hh:mm
   * @return sorted array of continuous hours
   */
  private buildHours(slotStartSet: Set<string>): string[] {
    const slotStartList: string[] = Array.from(slotStartSet).sort(); // 09:00 10:00 .. 17:00
    let delta: number = 100;
    let next: number | undefined;
    let added = false;
    for (const hh of slotStartList) {
      const hhNum = Number.parseInt(hh.replace(':', ''));
      if (next) {
        const delta2 = hhNum - next;
        if (delta2 > 0) {
          // console.log('-->', hh, delta2);
          if (!added && delta2 >= 100) {
            let prev = '' + (hhNum - delta);
            prev = prev.substring(0, 2) + ':' + prev.substring(2);
            // console.log('--> added', prev);
            slotStartSet.add(prev);
            added = true;
          }
        } else if (delta2 < 0) {
          console.log('--<', hh, delta2);
        } else {
          //  console.log('--0', hh, delta2);
          added = false;
        }
      }
      next = hhNum + delta;
    }
    return Array.from(slotStartSet).sort();
  } // buildHours

  /**
   * Build Week (called from selectTimezone, selectSelectSlots)
   * - week: SelectSlot[] - Mo, ..
   * - weekSlots: SelectSlot[][] - 8am, 9am, ...
   */
  private buildWeek(): void {
    if (!(this.tzInfo && this.slots.length > 0 && this.callType)) {
      return;
    }
    // Week of ..
    const first = this.slots[0];
    const todayUtc: Date = new Date(first.startMs);
    const dayUtc = todayUtc.getUTCDay(); // 0=Sunday
    const delta = dayUtc * this.ONEDAY;
    this.startOfTheWeek = new Date(todayUtc.getTime() - delta);
    //
    const slotStartSet = new Set<string>();
    let day: SelectDay | undefined;
    let dayLabel: string | undefined;
    let tabindex = 10;
    this.week = [];
    for (const slot of this.slots) {
      const date = new Date(slot.startMs);
      slot.dateLabel = TzUtil.formatDdMm(this.tzInfo, date); // date
      // day changed
      if (day == null || dayLabel !== slot.dateLabel) {
        day = new SelectDay();
        day.label = slot.dateLabel;
        day.slots = [];
        this.week.push(day);
        dayLabel = slot.dateLabel;
      }
      slot.label = TzUtil.formatHhMm(this.tzInfo, date); // time
      slot.slotName = TzUtil.formatHhMm24(this.tzInfo, date);
      slotStartSet.add(slot.slotName);
      slot.tabIndex = tabindex++;
      slot.isNightTime = this.isNightTime(slot);
      day.slots.push(slot);
      // this.log.debug('buildWeek', slot)();
    }
    // days Mo, Tu, ..
    const days: string[] = [];
    for (const dd of this.week) { // SelectDay
      days.push(dd.label ?? '?');
    }
    // this.log.debug('buildWeek', this.week)();

    const slotStartList: string[] = this.buildHours(slotStartSet); // 09:00 10:00 .. 17:00

    // slots 8am, 9am, ..
    this.weekSlots = [];
    // this.log.debug('createWeekX', slotStartSet, slotStartList);
    for (const sh of slotStartList) { // hours 09:00, 10:00, ..
      // console.log('-- sh', sh); // 09:00
      const hourSlots: SelectSlot[] = [];
      this.weekSlots.push(hourSlots);
      for (const selDay of this.week) { // SelectDay
        let ss: SelectSlot | undefined;
        for (const daySlot of selDay.slots) {
          if (daySlot.slotName === sh) {
            ss = daySlot;
            break;
          }
        }
        if (ss) {
          hourSlots.push(ss);
          // console.log('--- ss', ss.label, ss.isoDateTime);
        } else {
          hourSlots.push(new SelectSlot()); // empty
        }
      }
    }

    this.log.debug('buildWeek',
      'slots=' + this.slots.length + ' week=' + this.week.length
      + ' days=' + days.length
      + ' slotStartSet=' + slotStartSet.size + ' slotStartList=' + slotStartList.length
      + ' weekSlots=' + this.weekSlots.length)();
    this.selectStatus = 'built';
  } // buildWeek

} // WeekComponent

<p4d-week *ngIf="showWeek"></p4d-week>
<p4d-day *ngIf="showDay"></p4d-day>

<article *ngIf="showToggle">
  toggle:
  <button (click)="onClickWeek()"
          class="slds-button slds-text-link"
          i18n i18n-title id="select-week"
          title="Show Week options">week
  </button>
  &harr;
  <button (click)="onClickDay()"
          class="slds-button slds-text-link"
          i18n i18n-title id="select-day"
          title="Show Calendar with Day options">calendar
  </button>
</article>

import { TenantUserI } from 'accorto';
import { TenantI } from 'accorto';

/* GenTsInterface domain: CallSettings */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CallSettings
 */
export interface CallSettingsI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * Fallback or Selection
   * label: Heading Title
   */
  headingTitle?: string;

  /**
   * Fallback or Selection
   * label: Heading Subtitle
   */
  headingSubtitle?: string;

  headingInfo?: string;

  headingLogo?: string;

  /**
   * Select Call Type or Representative
   * label: Selection Title
   */
  selectTitle?: string;

  selectInstructions?: string;

  selectFooter?: string;

  numberOfCallTypes?: number;

  numberOfRepresentatives?: number;

  allowOwnDomain?: boolean;

  ownDomain?: string;

} // CallSettings

/** CallSettings Definition */
export const CallSettingsD = {
  recordTypeName: 'CallSettings',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  headingTitle: {
    n: 'headingTitle',
    t: 'string',
    d: 'Accorto Inc.'
  },
  headingSubtitle: {
    n: 'headingSubtitle',
    t: 'string',
    d: 'Please select'
  },
  headingInfo: {
    n: 'headingInfo',
    t: 'string'
  },
  headingLogo: {
    n: 'headingLogo',
    t: 'string'
  },
  selectTitle: {
    n: 'selectTitle',
    t: 'string',
    d: 'Please select'
  },
  selectInstructions: {
    n: 'selectInstructions',
    t: 'string'
  },
  selectFooter: {
    n: 'selectFooter',
    t: 'string'
  },
  numberOfCallTypes: {
    n: 'numberOfCallTypes',
    t: 'number'
  },
  numberOfRepresentatives: {
    n: 'numberOfRepresentatives',
    t: 'number'
  },
  allowOwnDomain: {
    n: 'allowOwnDomain',
    t: 'boolean'
  },
  ownDomain: {
    n: 'ownDomain',
    t: 'string'
  },
}; // CallSettings

import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AccortoService, CRequest, Logger} from 'accorto';
import {CallType} from '../model/call-type';
import {CResponseCallType} from '../model/c-response-call-type';
import {Pick4dUtil} from '../model/pick4d-util';

@Injectable({
  providedIn: 'root'
})
export class CallTypeService {

  private log: Logger = new Logger('CallTypeService');

  constructor(private http: HttpClient,
              private config: AccortoService,
              @Inject(PLATFORM_ID) private platform: Object) {
  }

  /**
   * Get Call Type
   */
  get(): Observable<CallType> {
    if (isPlatformServer(this.platform)) {
      this.log.info('get(server)')();
      return of(new CallType());
    }
    const url = this.config.server + '/callTypeP4d';
    //
    const request = new CRequest();
    this.config.setCRequest(request);
    this.log.info('get ' + url, request)();
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseCallType>(url, body, httpOptions)
      .pipe(
        tap(response => {
          this.log.info('get.response', response);
        }),
        map(response => response.callType), // json
        map(callType => Pick4dUtil.createCallType(callType))
      );
  } // get

}

import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {ClientStorage, Logger} from 'accorto';
import {AppState} from '../reducers';
import {ManageService} from './manage.service';
import {selectManageCall} from './manage.selectors';
import {Call} from '../model/call';
import {CResponseManage} from '../model/c-response-manage';
import {ConfirmComponent} from '../confirm/confirm.component';
import {manageCallResponse} from './manage.actions';
import {selectRefresh} from '../select/select.actions';

@Component({
  selector: 'p4d-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageComponent implements OnInit, OnDestroy {

  /** All Calls */
  calls: Call[] = [];

  manageError?: string;
  manageMessage?: string;

  /** Busy loading */
  busy = true;
  manageStatus: string = '-';

  cancelCallName?: string;

  // Call Status Cancelled
  readonly STA_CANCELLED = 'Cancelled';

  private log: Logger = new Logger('Manage');
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>,
              private route: ActivatedRoute,
              private router: Router,
              private service: ManageService) {
  } // constructor

  /** Get Add to Calendar Keys */
  getKeys(call: Call): string[] {
    return Object.keys(call.addToCalendar);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.manageError = '';
    this.manageMessage = '';

    // parameters
    this.subscriptions.push(
      this.route.params
        .subscribe((params: Params) => {
          const callId = params.id;
          this.log.debug('ngOnInit params', callId)();
          if (callId) {
            this.service.query(callId)
              .subscribe((response: CResponseManage) => {
                this.manageMessage = response.message;
                this.manageError = response.error;
                if (response.call) {
                  this.setCall(response.call);
                  this.manageStatus = 'call-p';
                }
              });
          }
        })
    );
    // call
    this.subscriptions.push(
      this.store.pipe(select(selectManageCall))
        .subscribe((call) => {
          this.log.debug('ngInit manage', call)();
          if (call) {
            this.setCall(call);
            this.manageMessage = undefined;
            this.manageStatus = 'call-s';
          }
        })
    );

    // search
    setTimeout(() => {
      if (this.calls.length === 0) {
        this.manageError = 'No Call found';
        const email = ClientStorage.get(ConfirmComponent.EMAIL);
        this.manageMessage = 'searching ...';
        this.busy = true;
        if (email) {
          this.service.search(email)
            .subscribe((response: CResponseManage) => {
              this.manageMessage = response.message;
              this.manageError = response.error;
              if (response.callList != null && response.callList.length > 0) {
                this.calls = response.callList;
                this.manageStatus = 'search';
              }
              this.busy = false;
            });
        }
        this.busy = false;
      }
    }, 1000);
  } // ngOnInit

  /** Cancel (1) - show cancel message */
  onCancel1Click(call: Call): void {
    this.log.info('onCancel1Click', call)();
    this.cancelCallName = call.name; // show Cancel Message
  } // onCancelClick

  /** Cancel (2) - so cancel */
  onCancel2Click(call: Call, cancelMessage: string): void {
    this.log.info('onCancel2Click', cancelMessage, call)();
    this.busy = true;
    this.service.cancel(call.name, cancelMessage)
      .subscribe((response: CResponseManage) => {
        this.manageError = response.error;
        this.manageMessage = response.message;
        if (response.call) {
          const index = this.calls.indexOf(call);
          if (index === -1) {
            this.calls = [];
            this.calls.push(response.call);
          } else {
            this.calls[ index ] = response.call;
          }
        }
        this.busy = false;
      });

  } // onCancelClick

  /**
   * Reschedule
   */
  onRescheduleClick(call: Call): void {
    if (call && call.status !== this.STA_CANCELLED) {
      this.store.dispatch(manageCallResponse({call})); // save
    }
    // refresh + goto select
    this.store.dispatch(selectRefresh());
    this.router.navigateByUrl('/select');
  } // onRescheduleClick

  /** Call Actions */
  showCallActions(call: Call): boolean {
    return call.status !== this.STA_CANCELLED;
  }

  /** show Cancel message/button */
  showCancelMessage(call: Call): boolean {
    return call.name === this.cancelCallName;
  }

  /**
   * Set the call
   * @param call new call
   */
  private setCall(call: Call): void {
    this.log.info('setCall', call)();
    this.calls.push(call);
    this.manageMessage = undefined;
    this.manageError = undefined;
    this.busy = false;
  } // setCall

} // ManageComponent

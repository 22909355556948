<div class="week">
  <button (click)="onClickPrevious()"
          [disabled]="!previousEnabled"
          class="slds-button slds-button_neutral"
          i18n-title
          id="prev"
          tabindex="0"
          title="Previous Week">
    <acc-icon [description]="'Previous Week'"
              [href]="'/assets/icons/utility-sprite/svg/symbols.svg#chevronleft'"></acc-icon>
  </button>
  <span [attr.data-date]="startOfTheWeek?.toISOString()"
        class="slds-m-horizontal_medium"
        id="dateInfo">Week of {{startLabel}}</span>
  <button (click)="onClickNext()"
          class="slds-button slds-button_neutral"
          i18n-title
          id="next"
          tabindex="0"
          title="Next Week">
    <acc-icon [description]="'Next Week'"
              [href]="'/assets/icons/utility-sprite/svg/symbols.svg#chevronright'"></acc-icon>
  </button>
</div>


<div class="slds-scrollable slds-m-vertical_small slds-is-relative" style="min-height: 400px">
  <table class="slds-table slds-table_bordered" id="week">
    <thead>
    <tr>
      <th *ngFor="let day of week"
          class="day-head slds-text-align_center" scope="col">{{day.label}}
      </th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let hourSlots of weekSlots; index as ih"
        class="slot" id="slot-h-{{ih}}">
      <td *ngFor="let slot of hourSlots; index as ix"
          class="slds-text-align_center day-slot"
          id="slot-x-{{ix}}">
        <a (click)="onSelectSlot(slot)"
           *ngIf="slot?.label"
           [id]="slot.startMs"
           [routerLink]="['/confirm', slot.startMs]"
           [tabindex]="0"
           class="slds-button slds-button_neutral slds-m-around_x-small timeSlot">
          <time [attr.datetime]="slot.isoDateTime"
                style="white-space: nowrap">{{slot.label}}
          </time>
          <span *ngIf="slot.isNightTime" class="slds-icon_container" title="Night Hours">
            <svg aria-hidden="true" class="slds-icon slds-icon-text-default slds-icon_x-small">
              <use xlink:href="/assets/icons/custom-sprite/svg/symbols.svg#custom10"></use>
            </svg>
            <span class="slds-assistive-text">Night Hours</span>
          </span>
        </a>
      </td>
    </tr>
    </tbody>

    <tfoot>
    <tr>
      <th *ngFor="let day of week"
          class="day-head slds-text-align_center dayLabel">{{day.label}}
      </th>
    </tr>
    </tfoot>
  </table>

  <acc-spinner *ngIf="busy"></acc-spinner>
</div>

<div class="p4d-status" id="selectStatus">{{selectStatus}}</div>

import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {isPlatformServer} from '@angular/common';
import {Observable, of, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {AccortoService, Logger} from 'accorto';
import {CRequestSelection} from '../model/c-request-selection';
import {CResponseSelection} from '../model/c-response-selection';
import {Pick4dCUtil} from '../model/pick4d-c-util';


@Injectable({
  providedIn: 'root'
})
export class SelectService {

  private log: Logger = new Logger('SelectService');

  constructor(private http: HttpClient,
              private config: AccortoService,
              @Inject(PLATFORM_ID) private platform: Object) {
  }

  /**
   * Get ClientResponse Selection
   * @param callTypeId call type id
   * @param startTimeMs start time
   * @param endTimeMs end time ms 0 for week end or -1 for all
   */
  get(callTypeId: number | string,
      startTimeMs: number, endTimeMs: number): Observable<CResponseSelection> {
    if (isPlatformServer(this.platform)) {
      this.log.info('get(server)')();
      return of(new CResponseSelection());
    }
    const url = this.config.server + '/selectP4d';

    const request = new CRequestSelection();
    this.config.setCRequest(request);
    request.callTypeId = callTypeId as number;
    request.startTimeMs = startTimeMs;
    request.endTimeMs = endTimeMs;
    //
    this.log.info('get ' + url, request)();
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseSelection>(url, body, httpOptions)
      .pipe(
        tap(response => {
          this.log.info('get.response', '#' + response.slots.length,
            response)();
        }),
        map(response => Pick4dCUtil.createCResponseSelection(response))
      );
  } // get


  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  } // handleError

} // SelectService

import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

/**
 * Application State
 */
export interface AppState {
  router: any;
}

/**
 * Reducers
 */
export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer
};


import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {Store} from '@ngrx/store';
import {NotificationService} from 'accorto';
import {callTypeError, callTypeRequest, callTypeResponse,} from './call-type.actions';
import {CallTypeService} from './call-type.service';
import {AppState} from '../reducers';
import {selectRequest} from '../select/select.actions';

@Injectable()
export class CallTypeEffects {


  callTypeRequest$ = createEffect(() => this.actions$.pipe(
    ofType(callTypeRequest),
    // tap((action) => {
    //   console.log('callTypeRequest$', action);
    // }),
    mergeMap(() => this.service.get()
      .pipe(
        catchError(err => {
          // console.log('callTypeRequest$.error', err); // TypeError: Cannot read property 'callType' of null
          this.store.dispatch(callTypeError({ err }));
          return EMPTY;
        })
      )
    ),
    map(callType => callTypeResponse({ callType }))
    ), {}
  );


  callTypeResponse$ = createEffect(() => this.actions$.pipe(
    ofType(callTypeResponse),
    tap((action) => {
      //  console.log('callTypeResponse$', action);
    }),
    map(action => action.callType.id),
    filter(callTypeId => !!callTypeId),
    tap((callTypeId) => {
      this.store.dispatch(selectRequest({
        callTypeId: callTypeId!,
        startTimeMs: 0, endTimeMs: 0
      }));
    })
    ), { dispatch: false }
  );


  callTypeError$ = createEffect(() => this.actions$.pipe(
    ofType(callTypeError),
    tap((action) => {
      const err = action.err;
      console.warn('callTypeError$', err);
      this.notify.addException('Connection Error (CT)', err);
    })
    ), { dispatch: false }
  );


  init$ = createEffect(() => this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    map((action) => {
      // console.log('callType.init$');
      return callTypeRequest();
    })
    ), {}
  );


  constructor(private actions$: Actions,
              private service: CallTypeService,
              private store: Store<AppState>,
              private notify: NotificationService) {
  }

} // CallTypeEffects

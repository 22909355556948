import { TenantUser } from 'accorto';
import { Tenant } from 'accorto';
import { CallRepI, CallRepD } from './call-rep-i';
import { DataRecordI } from 'accorto';
import { DataRecord } from 'accorto';
import { DataRecordF } from 'accorto';

/* GenTsClass domain: CallRep */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CallRep
 */
export class CallRep {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * fk TenantUser
   */
  public user?: TenantUser; // optional=true

  public userId?: number; // optional=true

  /**
   * label: Name (for events)
   */
  public screenName?: string; // optional=true

  /**
   * label: Timezone of CallRep
   */
  public timezoneName?: string; // optional=true

  /**
   * Contact needs to be in Timezone (GMT min)
   * label: Only Timezone Offset from
   */
  public timezoneOffsetFrom?: number; // optional=true

  /**
   * Contact needs to be in Timezone (GMT max)
   * label: Only Timezone Offset to
   */
  public timezoneOffsetTo?: number; // optional=true

  /**
   * label: Locale of CallRep
   */
  public localeName?: string; // optional=true

  /**
   * Only if contact has locale(s), separated by space or comma
   * label: Only Locales
   */
  public onlyLocales?: string; // optional=true

  /**
   * label: Email (for Events)
   */
  public email?: string; // optional=true

  /**
   * label: Phone (for events)
   */
  public phone?: string; // optional=true

  public mondayStart?: number; // optional=true

  public mondayEnd?: number; // optional=true

  public tuesdayStart?: number; // optional=true

  public tuesdayEnd?: number; // optional=true

  public wednesdayStart?: number; // optional=true

  public wednesdayEnd?: number; // optional=true

  public thursdayStart?: number; // optional=true

  public thursdayEnd?: number; // optional=true

  public fridayStart?: number; // optional=true

  public fridayEnd?: number; // optional=true

  public saturdayStart?: number; // optional=true

  public saturdayEnd?: number; // optional=true

  public sundayStart?: number; // optional=true

  public sundayEnd?: number; // optional=true

  public breakStart?: number; // optional=true

  public breakEnd?: number; // optional=true

  /**
   * label: Availability from Salesforce
   */
  public isGetAvailabilityFromSalesforce?: boolean; // optional=true

  /**
   * label: Salesforce Active
   */
  public isSfActive?: boolean; // optional=true

  public sfStatus?: string; // optional=true

  /**
   * All Calendars need to be free
   * label: Availability need from All
   */
  public isNeedAvailabilityInAllCalendars?: boolean; // optional=true

  /**
   * label: Availability from Google Calendar
   */
  public isGetAvailabilityFromGoogle?: boolean; // optional=true

  /**
   * label: Google Calendar Active
   */
  public isGcalActive?: boolean; // optional=true

  /**
   * label: Google Calendar Event Status
   */
  public gcalStatus?: string; // optional=true

  /**
   * label: Google Calendar Id
   */
  public gcalCalendarId?: string; // optional=true

  /**
   * Event Tracking
   * label: Google Calendar Resource Id
   */
  public gcalResourceId?: string; // optional=true

  /**
   * label: Google Calendar Access Token
   */
  public gcalAccessToken?: string; // optional=true

  /**
   * label: Google Calendar Refresh Token
   */
  public gcalRefreshToken?: string; // optional=true

  /**
   * label: Google Calendar Access Expiry
   */
  public gcalExpirationTime?: number; // optional=true

  /**
   * Event Tracking
   * label: Google Channel Id
   */
  public gcalChannelId?: string; // optional=true

  /**
   * 1=blue 2=lgreen 3=purple 4=pink 5=yellow 6=okker, 7=cyan, 8=gray 9=dblue, 10=green 11=red
   * label: Google Event Color
   */
  public gcalColor?: string; // optional=true

  /**
   * label: Use GoToMeeting
   */
  public isGtm?: boolean; // optional=true

  /**
   * label: GoToMeeting Active
   */
  public isGtmActive?: boolean; // optional=true

  /**
   * label: GoToMeeting Statue
   */
  public gtmStatus?: string; // optional=true

  /**
   * label: GoToMeeting User
   */
  public gtmUser?: string; // optional=true

  /**
   * label: GoToMeeting Password
   */
  public gtmPassword?: string; // optional=true

  /**
   * label: GoToMeeting Access Token
   */
  public gtmAccessToken?: string; // optional=true

  /**
   * label: GoToMeeting Refresh Token
   */
  public gtmRefreshToken?: string; // optional=true

  /**
   * label: GoToMeeting Access Expiry
   */
  public gtmExpirationTime?: number; // optional=true

  /**
   * label: GoToMeeting Organizer Key
   */
  public gtmOrganizerKey?: string; // optional=true


  /**
   * @return CallRep record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'CallRep';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): CallRep {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, CallRepD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, CallRepD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: CallRepI): CallRep {
    Object.keys(CallRepD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'user') { // fk
          this.user = new TenantUser();
          this.user.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // CallRep

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmComponent } from './confirm/confirm.component';
import { SelectComponent } from './select/select.component';
import { ErrorComponent } from './error/error.component';
import { ConfirmGuard } from './confirm/confirm.guard';
import { ManageComponent } from './manage/manage.component';


const routes: Routes = [
  {
    path: 'select',
    component: SelectComponent,
    data: {
      title: 'Select',
      description: 'Pick a good time for you',
      keywords: 'Schedule Meeting, Schedule Call'
    }
  },
  {
    path: 'confirm/:ts',
    component: ConfirmComponent,
    canActivate: [ ConfirmGuard ],
    data: {
      title: 'Confirm',
      description: 'Confirm the time for a call',
      keywords: 'Confirm Meeting, Confirm Call'
    }
  },
  {
    path: 'confirm',
    component: ConfirmComponent,
    canActivate: [ ConfirmGuard ],
    data: {
      title: 'Confirm',
      description: 'Confirm the time for a call',
      keywords: 'Confirm Meeting, Confirm Call'
    }
  },
  {
    path: 'manage/:id',
    component: ManageComponent,
    data: {
      title: 'Manage',
      description: 'Manage your call',
      keywords: 'Manage Meeting, Manage Call'
    }
  },
  {
    path: 'manage',
    component: ManageComponent,
    data: {
      title: 'Manage',
      description: 'Manage your call',
      keywords: 'Manage Meeting, Manage Call'
    }
  },

  { path: 'error', component: ErrorComponent },
  { path: '', redirectTo: '/select', pathMatch: 'full' },
  { path: '**', redirectTo: '/select' }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}

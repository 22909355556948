import { TenantUser } from 'accorto';
import { Tenant } from 'accorto';
import { CallRep } from './call-rep';
import { CallTypeI, CallTypeD } from './call-type-i';
import { DataRecordI } from 'accorto';
import { DataRecord } from 'accorto';
import { DataRecordF } from 'accorto';

/* GenTsClass domain: CallType */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CallType
 */
export class CallType {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * Use to directly select call type (lowercase, no space)
   * label: Short Name
   */
  public shortName?: string; // optional=true

  public headingTitle: string = 'Accorto Call';

  public headingSubtitle: string = 'Pick a good time for you';

  public headingInfo: string = 'Welcome to Accorto Time & Expense Support.\nPlease select a time slot convenient for you.\nLooking forward to helping you.';

  public headingLogo?: string; // optional=true

  public selectTitle: string = 'Please select a time slot';

  public selectInstructions: string = 'If you don"t find a good time for you, please send an email to info@accorto.com';

  public selectFooter?: string; // optional=true

  /**
   * How does the user select the slot
   * label: Select Slot UI
   */
  public selectSlotUI: string = 'Week';

  public confirmTitle: string = 'Please Confirm';

  public confirmEmail: string = 'Your Email';

  public confirmName: string = 'Your Name';

  public confirmPhone: string = 'Your Phone Number';

  public confirmInstructions: string = 'You will receive an invite and email with the details and the GoToMeeting URL for the call. Looking forward talking to you!';

  public confirmFooter?: string; // optional=true

  public confirmQuestion: string = 'What would you like to discuss/achieve in the call?';

  /**
   * Leave empty if no additional question
   * label: Additional Question 1 title
   */
  public confirmAdditional1?: string; // optional=true

  /**
   * Leave empty of free text otherwise options separated by |
   * label: Addl Question 1 options
   */
  public confirmAdditional1Options?: string; // optional=true

  /**
   * Leave empty if no additional question
   * label: Additional Question 2 title
   */
  public confirmAdditional2?: string; // optional=true

  /**
   * Leave empty of free text otherwise options separated by |
   * label: Addl Question 2 options
   */
  public confirmAdditional2Options?: string; // optional=true

  public durationInMinutes?: number; // optional=true

  public userCanSelectRepresentative?: boolean; // optional=true

  public showRepresentativeNameInSlots?: boolean; // optional=true

  public selectRepByPriority?: boolean; // optional=true

  /**
   * fk CallRep
   */
  public rep1?: CallRep; // optional=true

  public rep1Id?: number; // optional=true

  /**
   * fk CallRep
   */
  public rep2?: CallRep; // optional=true

  public rep2Id?: number; // optional=true

  /**
   * fk CallRep
   */
  public rep3?: CallRep; // optional=true

  public rep3Id?: number; // optional=true

  /**
   * fk CallRep
   */
  public rep4?: CallRep; // optional=true

  public rep4Id?: number; // optional=true

  public onlyLocale?: string; // optional=true

  public timezoneOffsetFrom?: number; // optional=true

  public timezoneOffsetTo?: number; // optional=true


  /**
   * @return CallType record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'CallType';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): CallType {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, CallTypeD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, CallTypeD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: CallTypeI): CallType {
    Object.keys(CallTypeD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'rep1') { // fk
          this.rep1 = new CallRep();
          this.rep1.setFromI(value);
        } else if (key === 'rep2') { // fk
          this.rep2 = new CallRep();
          this.rep2.setFromI(value);
        } else if (key === 'rep3') { // fk
          this.rep3 = new CallRep();
          this.rep3.setFromI(value);
        } else if (key === 'rep4') { // fk
          this.rep4 = new CallRep();
          this.rep4.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // CallType

import { SelectSlot } from './select-slot';
import { CResponse } from 'accorto';

/* GenTsClass client: com.pick4d.domain.CResponseSelection */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseSelection
 */
export class CResponseSelection extends CResponse {

  /**
   * label: Call Rep Id
   */
  public callRepId?: number; // optional=null

  /**
   * label: Call Rep Name
   */
  public callRepName?: string; // optional=null

  /**
   * label: Call Type Id
   */
  public callTypeId?: number; // optional=null

  /**
   * label: First Slot ms
   */
  public firstMs?: number; // optional=null

  /**
   * label: Last Slot ms
   */
  public lastMs?: number; // optional=null

  /**
   * label: Slots for number of days
   */
  public days?: number; // optional=null

  /**
   * label: Available Time Slots
   */
  public slots: SelectSlot[] = [];

} // CResponseSelection

import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {SelectState} from './select.reducer';
import {SelectSlot} from '../model/select-slot';
import {AppState} from '../reducers';


// export const selectSelectState = state => state.select;
export const selectSelectState = createFeatureSelector<SelectState>('select');


export const selectSelectStatus: MemoizedSelector<AppState, string | undefined> = createSelector(
  selectSelectState,
  selectState => selectState.status
);

export const selectSelectSlots: MemoizedSelector<AppState, SelectSlot[]> = createSelector(
  selectSelectState,
  selectState => selectState.slots
);

export const selectSelectSlot: MemoizedSelector<AppState, SelectSlot | undefined> = createSelector(
  selectSelectState,
  selectState => selectState.slot
);

import { CRequest } from 'accorto';

/* GenTsClass client: com.pick4d.domain.CRequestSelection */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: CRequestSelection
 */
export class CRequestSelection extends CRequest {

  /**
   * label: Call Type Id
   */
  public callTypeId?: number; // optional=null

  /**
   * label: End Time ms or -1 for all
   */
  public endTimeMs?: number; // optional=null

  /**
   * label: Start Time ms
   */
  public startTimeMs?: number; // optional=null

} // CRequestSelection

import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {select, Store} from '@ngrx/store';
import {selectTimezones} from './timezone.selectors';
import {TimezoneItem} from './timezone-item';
import {TzInfo} from '../model/tz-info';
import {Logger} from '../log/logger';
import {AccortoService} from '../accorto.service';
import {timezoneSetAction} from './timezone.actions';

/**
 * Timezone Selector
 * https://www.lightningdesignsystem.com/components/lookups/
 */
@Component({
  selector: 'acc-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimezoneComponent implements OnInit, OnDestroy {

  @ViewChild('tzSearch', {static: false})
  searchField?: ElementRef;
  /** Timezone Items */
  tzItems: TimezoneItem[] = [];
  /** Selected Item */
  selectedItem?: TimezoneItem;
  /** Select Value */
  searchValue = '';
  /** Select Placeholder */
  searchPlaceholder = '';
  /** Show Dropdown */
  showDropdown = false;
  /** Timer Seconds */
  theTimer?: any; // number|NodeJs.Timeout;
  /** Logger */
  private log: Logger = new Logger('Timezone');

  constructor(private store: Store<object>,
              @Inject(PLATFORM_ID) private platform: Object) {
  }

  ngOnDestroy(): void {
    if (this.theTimer) {
      clearInterval(this.theTimer);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.store.pipe(select(selectTimezones)).subscribe((resp) => {
        if (resp) {
          this.log.info('ngOnInit - tzList', resp)();
          if (resp.infos) {
            for (const ii of resp.infos) {
              const tzInfo = new TzInfo();
              Object.assign(tzInfo, ii);
              const tzItem = new TimezoneItem(tzInfo);
              this.tzItems.push(tzItem);
            }
          }
          this.reset();
        }
      });

      this.theTimer = setInterval(() => {
        this.tick();
      }, 1000);
    }
  } // ngOnInit

  trackByItem(index: number, item: TimezoneItem): number {
    return index;
  }

  onSearchKeyUp(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const value: string = inputElement.value;
    this.log.debug('onSearchKeyUp ' + value + ' (' + event.key + ')')();

    if (event.key === 'Escape') { // 27
      this.reset();
    } else if (event.key === 'Enter') { // 13
      for (const item of this.tzItems) {
        if (item.isDisplayed) { // select first
          this.set(item);
          break;
        }
      }
    } else if (event.key === 'ArrowDown') { // 40

    } else if (value !== '') {
      const regexp = new RegExp(value, 'i');
      for (const item of this.tzItems) {
        item.match(regexp);
      }
    }
  } // onSearchKeyUp

  onSearchFocus(): void {
    this.showDropdown = true;
    for (const item of this.tzItems) {
      item.match(undefined); // reset
    }
  }

  onComponentBlur(): void {
    this.showDropdown = false;
  }


  onItemClick(item: TimezoneItem): void {
    this.set(item);
  }

  onClearSelection(): void {
    this.set(undefined);
    this.onSearchFocus(); // show dropdown
    setTimeout(() => {
      if (this.searchField) {
        this.searchField.nativeElement.focus();
      }
    }, 50);
  }

  tick(): void {
    if (this.selectedItem) {
      this.selectedItem.tick();
    }
  } // tick


  reset(): void {
    this.searchValue = '';
    this.searchPlaceholder = 'Search Timezone ...';
    this.selectedItem = undefined;
    //
    this.selectedItem = this.findTz();
    if (this.selectedItem) {
      this.selectedItem.match(undefined);
    }
    this.set(this.selectedItem);
  } // reset

  /**
   * Set Timezone Item (ui) and Info (store)
   * @param item optional item
   */
  set(item: TimezoneItem | undefined): void {
    this.log.debug('set', item)();
    if (item) {
      item.match(undefined);
      this.store.dispatch(timezoneSetAction({tz: item.info}));
    }
    this.selectedItem = item;
    this.showDropdown = false;
  } // select

  /**
   * Find Timezone
   */
  findTz(): TimezoneItem | undefined {
    // by timezone id
    const timezoneId = AccortoService.timezoneId;
    if (timezoneId !== '-') {
      for (const item of this.tzItems) {
        if (item.info.id === timezoneId) {
          this.log.debug('findTz', timezoneId, item)();
          return item;
        }
      }
    }
    // by name / offset
    const dd = new Date();
    const offsetSeconds = -dd.getTimezoneOffset() * 60;
    let last: TimezoneItem | undefined = undefined;
    for (const item of this.tzItems) {
      if (item.info.offsetSeconds === offsetSeconds) {
        last = item;
        if (item.name === item.name.toLocaleUpperCase()) { // PST8PDT
          this.log.debug('findTz', 'offset=' + item.name, item)();
          return item;
        }
      }
    }
    //
    if (last === undefined && timezoneId) {
      const info = new TzInfo();
      info.id = timezoneId;
      info.fullName = 'browser';
      info.shortName = '';
      info.offsetSeconds = offsetSeconds;
      last = new TimezoneItem(info);
      this.log.debug('findTz', 'fallback=' + timezoneId, last)();
    }
    return last;
  } // findTz

} // TimezoneComponent

import {createAction, props} from '@ngrx/store';
import {SelectSlot} from '../model/select-slot';
import {CResponseSelection} from '../model/c-response-selection';

export enum SelectActionTypes {
  SelectRequest = '[Select] Request',
  SelectResponse = '[Select] Response',
  SelectRefresh = '[Select] Refresh',
  SelectError = '[Select] Error',
  SelectUserSlot = '[Select] UserSlot',
}


export const selectRequest = createAction(
  SelectActionTypes.SelectRequest,
  props<{ callTypeId: number | string, startTimeMs: number, endTimeMs: number }>()
);

export const selectRefresh = createAction(
  SelectActionTypes.SelectRefresh
);


export const selectResponse = createAction(
  SelectActionTypes.SelectResponse,
  props<{ selection: CResponseSelection }>()
);


export const selectError = createAction(
  SelectActionTypes.SelectError,
  props<{ err: any }>()
);


export const selectUserSlot = createAction(
  SelectActionTypes.SelectUserSlot,
  props<{ slot: SelectSlot }>()
);

import { CallType } from './call-type';
import { CResponse } from 'accorto';

/* GenTsClass client: com.pick4d.domain.CResponseCallType */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseCallType
 */
export class CResponseCallType extends CResponse {

  public callType?: CallType; // optional=null

} // CResponseCallType

import { createAction, props } from '@ngrx/store';
import { Call } from '../model/call';

export enum ManageActionTypes {
  ManageCallResponse = '[Manage] Call Response',
  ManageCallError = '[Manage] Call Error',
  ManageCallUpdate = '[Manage] Call Update',
  ManageCallCancel = '[Manage] Call Cancel'
}

export const manageCallResponse = createAction(
  ManageActionTypes.ManageCallResponse,
  props<{ call: Call }>()
);



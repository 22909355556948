import { Call } from './call';
import { CallRep } from './call-rep';
import { CallType } from './call-type';
import { DataRecord } from 'accorto';
import { CallSettings } from './call-settings';
import { AccortoUtil } from 'accorto';

/* GenTsClass utility: Pick4dUtil */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * Domain Generated Utility for com.pick4d.domain
 */
export class Pick4dUtil extends AccortoUtil {


  /**
   * Convert DataRecord to Call
   * @return populated Call
   * @param dr data record
   */
  static convertCall(dr: DataRecord): Call {
    const result = this.createCall(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      Object.assign(result, dr.changeMap);
    }
    return result;
  } // convertCall


  /**
   * Convert DataRecord to CallRep
   * @return populated CallRep
   * @param dr data record
   */
  static convertCallRep(dr: DataRecord): CallRep {
    const result = this.createCallRep(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      Object.assign(result, dr.changeMap);
    }
    return result;
  } // convertCallRep


  /**
   * Convert DataRecord to CallSettings
   * @return populated CallSettings
   * @param dr data record
   */
  static convertCallSettings(dr: DataRecord): CallSettings {
    const result = this.createCallSettings(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      Object.assign(result, dr.changeMap);
    }
    return result;
  } // convertCallSettings


  /**
   * Convert DataRecord to CallType
   * @return populated CallType
   * @param dr data record
   */
  static convertCallType(dr: DataRecord): CallType {
    const result = this.createCallType(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      Object.assign(result, dr.changeMap);
    }
    return result;
  } // convertCallType


  /**
   * Convert json to Call
   * @return populated Call
   * @param json json info
   */
  static createCall(json: any): Call {
    const entity: Call = new Call();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'callType') {
        entity.callType = this.createCallType(value); // dm
      } else if (key === 'callTypeId') {
        entity.callTypeId = Number(value);
      } else if (key === 'callRep') {
        entity.callRep = this.createCallRep(value); // dm
      } else if (key === 'callRepId') {
        entity.callRepId = Number(value);
      } else if (key === 'callDate') {
        entity.callDate = Number(value);
      } else if (key === 'callDateTime') {
        entity.callDateTime = Number(value);
      } else if (key === 'callMinutes') {
        entity.callMinutes = Number(value);
      } else if (key === 'callTime') {
        entity.callTime = Number(value);
      } else if (key === 'callTimezone') {
        entity.callTimezone = value;
      } else if (key === 'help') {
        entity.help = value;
      } else if (key === 'instructions') {
        entity.instructions = value;
      } else if (key === 'history') {
        entity.history = [];
        for (const vll of value) {
          entity.history.push(vll);
        }
      } else if (key === 'instructionsHtml') {
        entity.instructionsHtml = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'contactName') {
        entity.contactName = value;
      } else if (key === 'contactEmail') {
        entity.contactEmail = value;
      } else if (key === 'contactPhone') {
        entity.contactPhone = value;
      } else if (key === 'contactAnswer') {
        entity.contactAnswer = value;
      } else if (key === 'contactTimezone') {
        entity.contactTimezone = value;
      } else if (key === 'contactAdditional1') {
        entity.contactAdditional1 = value;
      } else if (key === 'contactAdditional2') {
        entity.contactAdditional2 = value;
      } else if (key === 'contactInfos') {
        entity.contactInfos = {};
        for (const vmm of Object.keys(value)) {
          entity.contactInfos[vmm] = value[vmm];
        }
      } else if (key === 'params') {
        entity.params = {};
        for (const vmm of Object.keys(value)) {
          entity.params[vmm] = value[vmm];
        }
      } else if (key === 'addToCalendar') {
        entity.addToCalendar = {};
        for (const vmm of Object.keys(value)) {
          entity.addToCalendar[vmm] = value[vmm];
        }
      } else if (key === 'status') {
        entity.status = value;
      } else if (key === 'manageUrl') {
        entity.manageUrl = value;
      } else if (key === 'callJoinUrl') {
        entity.callJoinUrl = value;
      } else if (key === 'callJoinInfo') {
        entity.callJoinInfo = value;
      } else if (key === 'calendarEventId') {
        entity.calendarEventId = value;
      } else if (key === 'calendarEventLink') {
        entity.calendarEventLink = value;
      } else if (key === 'calendarICalUID') {
        entity.calendarICalUID = value;
      } else if (key === 'cancelMessage') {
        entity.cancelMessage = value;
      } else if (key === 'gtmMeetingId') {
        entity.gtmMeetingId = value;
      } else if (key === 'notes') {
        entity.notes = value;
      } else if (key === 'inviteOthers') {
        entity.inviteOthers = value;
      } else if (key === 'account') {
        entity.account = this.createAccount(value); // dm
      } else if (key === 'accountId') {
        entity.accountId = Number(value);
      } else if (key === 'accountSfId') {
        entity.accountSfId = value;
      } else if (key === 'contact') {
        entity.contact = this.createContact(value); // dm
      } else if (key === 'contactId') {
        entity.contactId = Number(value);
      } else if (key === 'contactSfId') {
        entity.contactSfId = value;
      }
    } // for loop
    return entity;
  } // createCall


  /**
   * Convert json to CallRep
   * @return populated CallRep
   * @param json json info
   */
  static createCallRep(json: any): CallRep {
    const entity: CallRep = new CallRep();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'user') {
        entity.user = this.createTenantUser(value); // dm
      } else if (key === 'userId') {
        entity.userId = Number(value);
      } else if (key === 'screenName') {
        entity.screenName = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetFrom') {
        entity.timezoneOffsetFrom = Number(value);
      } else if (key === 'timezoneOffsetTo') {
        entity.timezoneOffsetTo = Number(value);
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'onlyLocales') {
        entity.onlyLocales = value;
      } else if (key === 'email') {
        entity.email = value;
      } else if (key === 'phone') {
        entity.phone = value;
      } else if (key === 'mondayStart') {
        entity.mondayStart = Number(value);
      } else if (key === 'mondayEnd') {
        entity.mondayEnd = Number(value);
      } else if (key === 'tuesdayStart') {
        entity.tuesdayStart = Number(value);
      } else if (key === 'tuesdayEnd') {
        entity.tuesdayEnd = Number(value);
      } else if (key === 'wednesdayStart') {
        entity.wednesdayStart = Number(value);
      } else if (key === 'wednesdayEnd') {
        entity.wednesdayEnd = Number(value);
      } else if (key === 'thursdayStart') {
        entity.thursdayStart = Number(value);
      } else if (key === 'thursdayEnd') {
        entity.thursdayEnd = Number(value);
      } else if (key === 'fridayStart') {
        entity.fridayStart = Number(value);
      } else if (key === 'fridayEnd') {
        entity.fridayEnd = Number(value);
      } else if (key === 'saturdayStart') {
        entity.saturdayStart = Number(value);
      } else if (key === 'saturdayEnd') {
        entity.saturdayEnd = Number(value);
      } else if (key === 'sundayStart') {
        entity.sundayStart = Number(value);
      } else if (key === 'sundayEnd') {
        entity.sundayEnd = Number(value);
      } else if (key === 'breakStart') {
        entity.breakStart = Number(value);
      } else if (key === 'breakEnd') {
        entity.breakEnd = Number(value);
      } else if (key === 'isGetAvailabilityFromSalesforce') {
        entity.isGetAvailabilityFromSalesforce = Boolean(value);
      } else if (key === 'isSfActive') {
        entity.isSfActive = Boolean(value);
      } else if (key === 'sfStatus') {
        entity.sfStatus = value;
      } else if (key === 'isNeedAvailabilityInAllCalendars') {
        entity.isNeedAvailabilityInAllCalendars = Boolean(value);
      } else if (key === 'isGetAvailabilityFromGoogle') {
        entity.isGetAvailabilityFromGoogle = Boolean(value);
      } else if (key === 'isGcalActive') {
        entity.isGcalActive = Boolean(value);
      } else if (key === 'gcalStatus') {
        entity.gcalStatus = value;
      } else if (key === 'gcalCalendarId') {
        entity.gcalCalendarId = value;
      } else if (key === 'gcalResourceId') {
        entity.gcalResourceId = value;
      } else if (key === 'gcalAccessToken') {
        entity.gcalAccessToken = value;
      } else if (key === 'gcalRefreshToken') {
        entity.gcalRefreshToken = value;
      } else if (key === 'gcalExpirationTime') {
        entity.gcalExpirationTime = Number(value);
      } else if (key === 'gcalChannelId') {
        entity.gcalChannelId = value;
      } else if (key === 'gcalColor') {
        entity.gcalColor = value;
      } else if (key === 'isGtm') {
        entity.isGtm = Boolean(value);
      } else if (key === 'isGtmActive') {
        entity.isGtmActive = Boolean(value);
      } else if (key === 'gtmStatus') {
        entity.gtmStatus = value;
      } else if (key === 'gtmUser') {
        entity.gtmUser = value;
      } else if (key === 'gtmPassword') {
        entity.gtmPassword = value;
      } else if (key === 'gtmAccessToken') {
        entity.gtmAccessToken = value;
      } else if (key === 'gtmRefreshToken') {
        entity.gtmRefreshToken = value;
      } else if (key === 'gtmExpirationTime') {
        entity.gtmExpirationTime = Number(value);
      } else if (key === 'gtmOrganizerKey') {
        entity.gtmOrganizerKey = value;
      }
    } // for loop
    return entity;
  } // createCallRep


  /**
   * Convert json to CallSettings
   * @return populated CallSettings
   * @param json json info
   */
  static createCallSettings(json: any): CallSettings {
    const entity: CallSettings = new CallSettings();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'headingTitle') {
        entity.headingTitle = value;
      } else if (key === 'headingSubtitle') {
        entity.headingSubtitle = value;
      } else if (key === 'headingInfo') {
        entity.headingInfo = value;
      } else if (key === 'headingLogo') {
        entity.headingLogo = value;
      } else if (key === 'selectTitle') {
        entity.selectTitle = value;
      } else if (key === 'selectInstructions') {
        entity.selectInstructions = value;
      } else if (key === 'selectFooter') {
        entity.selectFooter = value;
      } else if (key === 'numberOfCallTypes') {
        entity.numberOfCallTypes = Number(value);
      } else if (key === 'numberOfRepresentatives') {
        entity.numberOfRepresentatives = Number(value);
      } else if (key === 'allowOwnDomain') {
        entity.allowOwnDomain = Boolean(value);
      } else if (key === 'ownDomain') {
        entity.ownDomain = value;
      }
    } // for loop
    return entity;
  } // createCallSettings


  /**
   * Convert json to CallType
   * @return populated CallType
   * @param json json info
   */
  static createCallType(json: any): CallType {
    const entity: CallType = new CallType();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'shortName') {
        entity.shortName = value;
      } else if (key === 'headingTitle') {
        entity.headingTitle = value;
      } else if (key === 'headingSubtitle') {
        entity.headingSubtitle = value;
      } else if (key === 'headingInfo') {
        entity.headingInfo = value;
      } else if (key === 'headingLogo') {
        entity.headingLogo = value;
      } else if (key === 'selectTitle') {
        entity.selectTitle = value;
      } else if (key === 'selectInstructions') {
        entity.selectInstructions = value;
      } else if (key === 'selectFooter') {
        entity.selectFooter = value;
      } else if (key === 'selectSlotUI') {
        entity.selectSlotUI = value;
      } else if (key === 'confirmTitle') {
        entity.confirmTitle = value;
      } else if (key === 'confirmEmail') {
        entity.confirmEmail = value;
      } else if (key === 'confirmName') {
        entity.confirmName = value;
      } else if (key === 'confirmPhone') {
        entity.confirmPhone = value;
      } else if (key === 'confirmInstructions') {
        entity.confirmInstructions = value;
      } else if (key === 'confirmFooter') {
        entity.confirmFooter = value;
      } else if (key === 'confirmQuestion') {
        entity.confirmQuestion = value;
      } else if (key === 'confirmAdditional1') {
        entity.confirmAdditional1 = value;
      } else if (key === 'confirmAdditional1Options') {
        entity.confirmAdditional1Options = value;
      } else if (key === 'confirmAdditional2') {
        entity.confirmAdditional2 = value;
      } else if (key === 'confirmAdditional2Options') {
        entity.confirmAdditional2Options = value;
      } else if (key === 'durationInMinutes') {
        entity.durationInMinutes = Number(value);
      } else if (key === 'userCanSelectRepresentative') {
        entity.userCanSelectRepresentative = Boolean(value);
      } else if (key === 'showRepresentativeNameInSlots') {
        entity.showRepresentativeNameInSlots = Boolean(value);
      } else if (key === 'selectRepByPriority') {
        entity.selectRepByPriority = Boolean(value);
      } else if (key === 'rep1') {
        entity.rep1 = this.createCallRep(value); // dm
      } else if (key === 'rep1Id') {
        entity.rep1Id = Number(value);
      } else if (key === 'rep2') {
        entity.rep2 = this.createCallRep(value); // dm
      } else if (key === 'rep2Id') {
        entity.rep2Id = Number(value);
      } else if (key === 'rep3') {
        entity.rep3 = this.createCallRep(value); // dm
      } else if (key === 'rep3Id') {
        entity.rep3Id = Number(value);
      } else if (key === 'rep4') {
        entity.rep4 = this.createCallRep(value); // dm
      } else if (key === 'rep4Id') {
        entity.rep4Id = Number(value);
      } else if (key === 'onlyLocale') {
        entity.onlyLocale = value;
      } else if (key === 'timezoneOffsetFrom') {
        entity.timezoneOffsetFrom = Number(value);
      } else if (key === 'timezoneOffsetTo') {
        entity.timezoneOffsetTo = Number(value);
      }
    } // for loop
    return entity;
  } // createCallType

} // Pick4dUtil

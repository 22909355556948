import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {AccortoService, ClientStorage, Logger} from 'accorto';

import {AppState} from "../reducers";
import {selectCallType} from "../call-type/call-type.selectors";
import {CallType} from "../model/call-type";


/**
 * Select Component
 */
@Component({
  selector: 'p4d-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnInit, OnDestroy {

  showWeek: boolean = true;
  showDay: boolean = false;
  showToggle: boolean = true;

  callType?: CallType;

  private log: Logger = new Logger('Select');
  private subscriptions: Subscription[] = [];

  private readonly KEY_DAY = 'pick4d-select-showDay';
  private readonly KEY_TOGGLE = 'pick4d-select-showToggle';

  constructor(private store: Store<AppState>,
              private config: AccortoService) {
    // (0) Client Storage
    const isDay = ClientStorage.getBoolean(this.KEY_DAY, false);
    if (isDay) {
      this.setDay();
    } else {
      this.setWeek();
    }
    this.showToggle = ClientStorage.getBoolean(this.KEY_TOGGLE, true);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit() {
    const type = this.config.getEnv('ui');
    const day = ClientStorage.get(this.KEY_DAY); // previous selection

    // (1) Device Size
    if (this.config.isCurrentSizeXSmall()) { // < 480
      this.setDay();
      this.showToggle = true;
    } else if (type === 'week') { // (2) Request Parameter
      this.setWeek();
      this.showToggle = false;
    } else if (type === 'day') {
      this.setDay();
      this.showToggle = false;
    } else {                      // (3) call type
      this.subscriptions.push(
        this.store.pipe(select(selectCallType))
          .subscribe((callType) => {
            if (callType) {
              this.log.debug('ngOnInit - callType', callType.selectSlotUI)();
              this.callType = callType;
              // selectSlotUi = Week | WeekOnly | Day | DayOnly
              if (callType.selectSlotUI === 'Week') {
                if (day === 'true') {
                  this.setDay();
                } else {
                  this.onClickWeek();
                }
                this.showToggle = true;
              } else if (callType.selectSlotUI === 'WeekOnly') {
                this.onClickWeek();
                this.showToggle = false;
              } else if (callType.selectSlotUI === 'Day') {
                if (day === 'false') {
                  this.setWeek();
                } else {
                  this.onClickDay();
                }
                this.showToggle = true;
              } else if (callType.selectSlotUI === 'DayOnly') {
                this.onClickDay();
                this.showToggle = false;
              }
            }
            ClientStorage.getBoolean(this.KEY_TOGGLE, this.showToggle);
          })
      );
    }
  } // ngOnInit


  onClickWeek(): void {
    this.setWeek();
    ClientStorage.set(this.KEY_DAY, 'false');
  }

  setWeek(): void {
    this.showWeek = true;
    this.showDay = false;
  }

  onClickDay(): void {
    this.setDay();
    ClientStorage.set(this.KEY_DAY, 'true');
  }

  setDay(): void {
    this.showWeek = false;
    this.showDay = true;
  }

} // SelectComponent

import { TenantUserI } from 'accorto';
import { TenantI } from 'accorto';

/* GenTsInterface domain: CallRep */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CallRep
 */
export interface CallRepI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * fk TenantUserI
   */
  user?: TenantUserI;

  /**
   * fk TenantUserI key
   */
  userId?: number|string;

  /**
   * label: Name (for events)
   */
  screenName?: string;

  /**
   * label: Timezone of CallRep
   */
  timezoneName?: string;

  /**
   * Contact needs to be in Timezone (GMT min)
   * label: Only Timezone Offset from
   */
  timezoneOffsetFrom?: number;

  /**
   * Contact needs to be in Timezone (GMT max)
   * label: Only Timezone Offset to
   */
  timezoneOffsetTo?: number;

  /**
   * label: Locale of CallRep
   */
  localeName?: string;

  /**
   * Only if contact has locale(s), separated by space or comma
   * label: Only Locales
   */
  onlyLocales?: string;

  /**
   * label: Email (for Events)
   */
  email?: string;

  /**
   * label: Phone (for events)
   */
  phone?: string;

  mondayStart?: number;

  mondayEnd?: number;

  tuesdayStart?: number;

  tuesdayEnd?: number;

  wednesdayStart?: number;

  wednesdayEnd?: number;

  thursdayStart?: number;

  thursdayEnd?: number;

  fridayStart?: number;

  fridayEnd?: number;

  saturdayStart?: number;

  saturdayEnd?: number;

  sundayStart?: number;

  sundayEnd?: number;

  breakStart?: number;

  breakEnd?: number;

  /**
   * label: Availability from Salesforce
   */
  isGetAvailabilityFromSalesforce?: boolean;

  /**
   * label: Salesforce Active
   */
  isSfActive?: boolean;

  sfStatus?: string;

  /**
   * All Calendars need to be free
   * label: Availability need from All
   */
  isNeedAvailabilityInAllCalendars?: boolean;

  /**
   * label: Availability from Google Calendar
   */
  isGetAvailabilityFromGoogle?: boolean;

  /**
   * label: Google Calendar Active
   */
  isGcalActive?: boolean;

  /**
   * label: Google Calendar Event Status
   */
  gcalStatus?: string;

  /**
   * label: Google Calendar Id
   */
  gcalCalendarId?: string;

  /**
   * Event Tracking
   * label: Google Calendar Resource Id
   */
  gcalResourceId?: string;

  /**
   * label: Google Calendar Access Token
   */
  gcalAccessToken?: string;

  /**
   * label: Google Calendar Refresh Token
   */
  gcalRefreshToken?: string;

  /**
   * label: Google Calendar Access Expiry
   */
  gcalExpirationTime?: number;

  /**
   * Event Tracking
   * label: Google Channel Id
   */
  gcalChannelId?: string;

  /**
   * 1=blue 2=lgreen 3=purple 4=pink 5=yellow 6=okker, 7=cyan, 8=gray 9=dblue, 10=green 11=red
   * label: Google Event Color
   */
  gcalColor?: string;

  /**
   * label: Use GoToMeeting
   */
  isGtm?: boolean;

  /**
   * label: GoToMeeting Active
   */
  isGtmActive?: boolean;

  /**
   * label: GoToMeeting Statue
   */
  gtmStatus?: string;

  /**
   * label: GoToMeeting User
   */
  gtmUser?: string;

  /**
   * label: GoToMeeting Password
   */
  gtmPassword?: string;

  /**
   * label: GoToMeeting Access Token
   */
  gtmAccessToken?: string;

  /**
   * label: GoToMeeting Refresh Token
   */
  gtmRefreshToken?: string;

  /**
   * label: GoToMeeting Access Expiry
   */
  gtmExpirationTime?: number;

  /**
   * label: GoToMeeting Organizer Key
   */
  gtmOrganizerKey?: string;

} // CallRep

/** CallRep Definition */
export const CallRepD = {
  recordTypeName: 'CallRep',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  user: {
    n: 'user',
    t: 'TenantUserI'
  },
  userId: {
    n: 'userId',
    t: 'number|string'
  },
  screenName: {
    n: 'screenName',
    t: 'string'
  },
  timezoneName: {
    n: 'timezoneName',
    t: 'string'
  },
  timezoneOffsetFrom: {
    n: 'timezoneOffsetFrom',
    t: 'number'
  },
  timezoneOffsetTo: {
    n: 'timezoneOffsetTo',
    t: 'number'
  },
  localeName: {
    n: 'localeName',
    t: 'string'
  },
  onlyLocales: {
    n: 'onlyLocales',
    t: 'string'
  },
  email: {
    n: 'email',
    t: 'string'
  },
  phone: {
    n: 'phone',
    t: 'string'
  },
  mondayStart: {
    n: 'mondayStart',
    t: 'number'
  },
  mondayEnd: {
    n: 'mondayEnd',
    t: 'number'
  },
  tuesdayStart: {
    n: 'tuesdayStart',
    t: 'number'
  },
  tuesdayEnd: {
    n: 'tuesdayEnd',
    t: 'number'
  },
  wednesdayStart: {
    n: 'wednesdayStart',
    t: 'number'
  },
  wednesdayEnd: {
    n: 'wednesdayEnd',
    t: 'number'
  },
  thursdayStart: {
    n: 'thursdayStart',
    t: 'number'
  },
  thursdayEnd: {
    n: 'thursdayEnd',
    t: 'number'
  },
  fridayStart: {
    n: 'fridayStart',
    t: 'number'
  },
  fridayEnd: {
    n: 'fridayEnd',
    t: 'number'
  },
  saturdayStart: {
    n: 'saturdayStart',
    t: 'number'
  },
  saturdayEnd: {
    n: 'saturdayEnd',
    t: 'number'
  },
  sundayStart: {
    n: 'sundayStart',
    t: 'number'
  },
  sundayEnd: {
    n: 'sundayEnd',
    t: 'number'
  },
  breakStart: {
    n: 'breakStart',
    t: 'number'
  },
  breakEnd: {
    n: 'breakEnd',
    t: 'number'
  },
  isGetAvailabilityFromSalesforce: {
    n: 'isGetAvailabilityFromSalesforce',
    t: 'boolean'
  },
  isSfActive: {
    n: 'isSfActive',
    t: 'boolean'
  },
  sfStatus: {
    n: 'sfStatus',
    t: 'string'
  },
  isNeedAvailabilityInAllCalendars: {
    n: 'isNeedAvailabilityInAllCalendars',
    t: 'boolean'
  },
  isGetAvailabilityFromGoogle: {
    n: 'isGetAvailabilityFromGoogle',
    t: 'boolean'
  },
  isGcalActive: {
    n: 'isGcalActive',
    t: 'boolean'
  },
  gcalStatus: {
    n: 'gcalStatus',
    t: 'string'
  },
  gcalCalendarId: {
    n: 'gcalCalendarId',
    t: 'string'
  },
  gcalResourceId: {
    n: 'gcalResourceId',
    t: 'string'
  },
  gcalAccessToken: {
    n: 'gcalAccessToken',
    t: 'string'
  },
  gcalRefreshToken: {
    n: 'gcalRefreshToken',
    t: 'string'
  },
  gcalExpirationTime: {
    n: 'gcalExpirationTime',
    t: 'number'
  },
  gcalChannelId: {
    n: 'gcalChannelId',
    t: 'string'
  },
  gcalColor: {
    n: 'gcalColor',
    t: 'string'
  },
  isGtm: {
    n: 'isGtm',
    t: 'boolean'
  },
  isGtmActive: {
    n: 'isGtmActive',
    t: 'boolean'
  },
  gtmStatus: {
    n: 'gtmStatus',
    t: 'string'
  },
  gtmUser: {
    n: 'gtmUser',
    t: 'string'
  },
  gtmPassword: {
    n: 'gtmPassword',
    t: 'string'
  },
  gtmAccessToken: {
    n: 'gtmAccessToken',
    t: 'string'
  },
  gtmRefreshToken: {
    n: 'gtmRefreshToken',
    t: 'string'
  },
  gtmExpirationTime: {
    n: 'gtmExpirationTime',
    t: 'number'
  },
  gtmOrganizerKey: {
    n: 'gtmOrganizerKey',
    t: 'string'
  },
}; // CallRep

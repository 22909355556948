import { CRequest } from 'accorto';
import { DataRecord } from 'accorto';

/* GenTsClass client: com.pick4d.domain.CRequestManage */
/* tslint:disable max-line-length no-inferrable-types */

export enum Pick4dType {
  CANCEL = 'CANCEL',
  CHANGE = 'CHANGE',
  UPDATE = 'UPDATE'
}

/**
 * extends: CRequest
 * name: CRequestManage
 */
export class CRequestManage extends CRequest {

  /**
   * label: Call Id
   */
  public callId?: number; // optional=null

  /**
   * label: Call Name for search
   */
  public callName?: string; // optional=null

  /**
   * label: Cancel Message
   */
  public cancelMessage?: string; // optional=null

  /**
   * label: Call Record Changes
   */
  public record?: DataRecord; // optional=null

  /**
   * label: Contact Email for search
   */
  public contactEmail?: string; // optional=null

  public type?: Pick4dType; // optional=null

  public message?: string; // optional=null

} // CRequestManage

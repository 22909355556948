import { AccountI } from 'accorto';
import { TenantUserI } from 'accorto';
import { CallRepI } from './call-rep-i';
import { CallTypeI } from './call-type-i';
import { ContactI } from 'accorto';
import { TenantI } from 'accorto';

/* GenTsInterface domain: Call */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Call
 */
export interface CallI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk CallTypeI
   * label: Call Type
   */
  callType?: CallTypeI;

  /**
   * parent fk CallTypeI key
   * label: Call Type
   */
  callTypeId?: number|string;

  /**
   * fk CallRepI
   * label: Call Rep
   */
  callRep?: CallRepI;

  /**
   * fk CallRepI key
   * label: Call Rep
   */
  callRepId?: number|string;

  /**
   * label: Call Date
   */
  callDate?: number;

  /**
   * label: Call Date/Time
   */
  callDateTime?: number;

  /**
   * Minutes
   * label: Duration (min)
   */
  callMinutes?: number;

  /**
   * label: Call Time
   */
  callTime?: number;

  /**
   * label: Call Timezone
   */
  callTimezone?: string;

  /**
   * label: Help
   */
  help?: string;

  /**
   * label: Call Instructions
   */
  instructions?: string;

  /**
   * label: History
   */
  history?: string[];

  /**
   * label: Call Instructions (html)
   */
  instructionsHtml?: string;

  /**
   * label: Locale
   */
  localeName?: string;

  /**
   * label: Contact Name
   */
  contactName?: string;

  /**
   * label: Contact Email
   */
  contactEmail?: string;

  /**
   * label: Contact Phone
   */
  contactPhone?: string;

  /**
   * label: Contact Answer
   */
  contactAnswer?: string;

  /**
   * label: Contact Timezone
   */
  contactTimezone?: string;

  /**
   * label: Contact Additional (1)
   */
  contactAdditional1?: string;

  /**
   * label: Contact Additional (2)
   */
  contactAdditional2?: string;

  /**
   * label: Contact Infos
   */
  contactInfos?: { [key: string]: string|undefined|null };

  /**
   * label: Parameters
   */
  params?: { [key: string]: string|undefined|null };

  /**
   * label: Add to Calendar
   */
  addToCalendar?: { [key: string]: string|undefined|null };

  /**
   * label: Status
   */
  status?: string;

  /**
   * label: Manage Url
   */
  manageUrl?: string;

  /**
   * label: Call Join Url
   */
  callJoinUrl?: string;

  /**
   * label: Call Join Info
   */
  callJoinInfo?: string;

  /**
   * label: Calendar Event Id
   */
  calendarEventId?: string;

  /**
   * label: Calendar Event Link
   */
  calendarEventLink?: string;

  /**
   * label: Calendar ICalUID
   */
  calendarICalUID?: string;

  /**
   * label: Cancel Message
   */
  cancelMessage?: string;

  /**
   * label: GTM Meeting Id
   */
  gtmMeetingId?: string;

  /**
   * label: Notes
   */
  notes?: string;

  /**
   * label: Invite Others
   */
  inviteOthers?: string;

  /**
   * fk AccountI
   * label: Account
   */
  account?: AccountI;

  /**
   * fk AccountI key
   * label: Account
   */
  accountId?: number|string;

  /**
   * label: Account
   */
  accountSfId?: string;

  /**
   * fk ContactI
   * label: Contact
   */
  contact?: ContactI;

  /**
   * fk ContactI key
   * label: Contact
   */
  contactId?: number|string;

  /**
   * label: Contact
   */
  contactSfId?: string;

} // Call

/** Call Definition */
export const CallD = {
  recordTypeName: 'Call',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  callType: {
    n: 'callType',
    t: 'CallTypeI'
  },
  callTypeId: {
    n: 'callTypeId',
    t: 'number|string'
  },
  callRep: {
    n: 'callRep',
    t: 'CallRepI'
  },
  callRepId: {
    n: 'callRepId',
    t: 'number|string'
  },
  callDate: {
    n: 'callDate',
    t: 'number'
  },
  callDateTime: {
    n: 'callDateTime',
    t: 'number'
  },
  callMinutes: {
    n: 'callMinutes',
    t: 'number'
  },
  callTime: {
    n: 'callTime',
    t: 'number'
  },
  callTimezone: {
    n: 'callTimezone',
    t: 'string'
  },
  help: {
    n: 'help',
    t: 'string'
  },
  instructions: {
    n: 'instructions',
    t: 'string'
  },
  history: {
    array: true,
    n: 'history',
    t: 'string'
  },
  instructionsHtml: {
    n: 'instructionsHtml',
    t: 'string'
  },
  localeName: {
    n: 'localeName',
    t: 'string'
  },
  contactName: {
    n: 'contactName',
    t: 'string'
  },
  contactEmail: {
    n: 'contactEmail',
    t: 'string'
  },
  contactPhone: {
    n: 'contactPhone',
    t: 'string'
  },
  contactAnswer: {
    n: 'contactAnswer',
    t: 'string'
  },
  contactTimezone: {
    n: 'contactTimezone',
    t: 'string'
  },
  contactAdditional1: {
    n: 'contactAdditional1',
    t: 'string'
  },
  contactAdditional2: {
    n: 'contactAdditional2',
    t: 'string'
  },
  contactInfos: {
    n: 'contactInfos',
    t: '{ [key: string]: string|undefined|null }'
  },
  params: {
    n: 'params',
    t: '{ [key: string]: string|undefined|null }'
  },
  addToCalendar: {
    n: 'addToCalendar',
    t: '{ [key: string]: string|undefined|null }'
  },
  status: {
    n: 'status',
    t: 'string'
  },
  manageUrl: {
    n: 'manageUrl',
    t: 'string'
  },
  callJoinUrl: {
    n: 'callJoinUrl',
    t: 'string'
  },
  callJoinInfo: {
    n: 'callJoinInfo',
    t: 'string'
  },
  calendarEventId: {
    n: 'calendarEventId',
    t: 'string'
  },
  calendarEventLink: {
    n: 'calendarEventLink',
    t: 'string'
  },
  calendarICalUID: {
    n: 'calendarICalUID',
    t: 'string'
  },
  cancelMessage: {
    n: 'cancelMessage',
    t: 'string'
  },
  gtmMeetingId: {
    n: 'gtmMeetingId',
    t: 'string'
  },
  notes: {
    n: 'notes',
    t: 'string'
  },
  inviteOthers: {
    n: 'inviteOthers',
    t: 'string'
  },
  account: {
    n: 'account',
    t: 'AccountI'
  },
  accountId: {
    n: 'accountId',
    t: 'number|string'
  },
  accountSfId: {
    n: 'accountSfId',
    t: 'string'
  },
  contact: {
    n: 'contact',
    t: 'ContactI'
  },
  contactId: {
    n: 'contactId',
    t: 'number|string'
  },
  contactSfId: {
    n: 'contactSfId',
    t: 'string'
  },
}; // Call

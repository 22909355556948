import {TzInfo} from '../model/tz-info';
import {AccortoService} from '../accorto.service';

export class TimezoneItem {

  firstLine?: string;
  firstLineTime?: string;
  secondLine?: string;
  isDisplayed?: boolean;

  constructor(public info: TzInfo) {
    this.match(undefined);
  }

  /**
   * Timezone id w/o _
   */
  get name(): string {
    return this.info.id ? this.info.id.replace('_', ' ') : '';
  }

  /**
   * Match/display
   * @param regexp const regexp = new RegExp(value, 'i');
   */
  match(regexp: RegExp | undefined): void {
    this.firstLine = this.name;
    this.secondLine = this.info.fullName + ' (' + this.info.shortName + ')';
    this.isDisplayed = true;
    if (regexp) {
      this.isDisplayed = false;
      const hdr = this.firstLine;
      if (hdr.match(regexp)) {
        this.isDisplayed = true;
        const result: RegExpExecArray | null = regexp.exec(hdr);
        if (result) {
          const term = result[0];
          // highlight first
          this.firstLine = hdr.substring(0, result.index)
            + '<b>' + term + '</b>'
            + hdr.substring(result.index + term.length);
        }
      }
      // 2nd line
      const fullName = this.info.fullName;
      this.secondLine = fullName;
      if (fullName.match(regexp)) {
        this.isDisplayed = true;
        const result: RegExpExecArray | null = regexp.exec(fullName);
        if (result) {
          const term = result[0];
          // highlight first
          this.secondLine = fullName.substring(0, result.index)
            + '<b>' + term + '</b>'
            + fullName.substring(result.index + term.length);
        }
      }
      // add short name to 2nd line
      const shortName = this.info.shortName;
      if (shortName.match(regexp)) {
        this.isDisplayed = true;
        const result: RegExpExecArray | null = regexp.exec(shortName);
        if (result) {
          const term = result[0];
          // highlight first
          this.secondLine += ' (' + shortName.substring(0, result.index)
            + '<b>' + term + '</b>'
            + shortName.substring(result.index + term.length)
            + ')';
        }
      } else {
        this.secondLine += ' (' + this.info.shortName + ')';
      }
    } // regexp
    this.firstLineTime = this.firstLine + this.getTime(false);
  } // match

  /**
   * Update time
   */
  tick(): void {
    this.firstLineTime = this.firstLine + this.getTime(true);
  }

  /**
   * get time info
   */
  getTime(withSeconds: boolean): string {
    const dd = new Date();
    const timeMs = dd.getTime()
      + (dd.getTimezoneOffset() * 60 * 1000)
      + (this.info.offsetSeconds * 1000);
    const dd2 = new Date(timeMs);
    if (withSeconds) {
      return ' - ' + dd2.toLocaleTimeString();
    }
    return ' - ' + dd2.toLocaleTimeString(
      AccortoService.locale,
      { hour: '2-digit', minute: '2-digit' });
  }

} // TimezoneItem

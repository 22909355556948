import { Call } from './call';
import { DataRecord } from 'accorto';
import { CResponse } from 'accorto';

/* GenTsClass client: com.pick4d.domain.CResponseManage */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseManage
 */
export class CResponseManage extends CResponse {

  /**
   * label: The Call
   */
  public call?: Call; // optional=null

  /**
   * label: Call List
   */
  public callList: Call[] = [];

  /**
   * label: Call Record
   */
  public record?: DataRecord; // optional=null

} // CResponseManage

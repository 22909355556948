import {Action, createReducer, on} from '@ngrx/store';
import {CallType} from '../model/call-type';
import * as CallTypeActions from './call-type.actions';

/**
 * Call Type State
 */
export interface CallTypeState {
  status?: string;
  callType?: CallType;
}

export const initialState: CallTypeState = {
  status: undefined,
  callType: undefined
};

/**
 * Call Type Reducer
 * @param state call type state
 * @param action call type action
 */
const callTypeReducer0 = createReducer(
  initialState,
  on(CallTypeActions.callTypeRequest,
    (state, action) => {
      return {
        ...state,
        status: 'requested'
      };
    }),
  on(CallTypeActions.callTypeResponse,
    (state, action) => {
      return {
        status: 'ok',
        callType: action.callType
      };
    }),
  on(CallTypeActions.callTypeError,
    (state, action) => {
      return {
        status: 'error',
        callType: undefined
      };
    }),
);


export function callTypeReducer(state: CallTypeState, action: Action): CallTypeState {
  return callTypeReducer0(state, action);
} // reducer

import { TenantUserI } from 'accorto';
import { CallRepI } from './call-rep-i';
import { TenantI } from 'accorto';

/* GenTsInterface domain: CallType */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CallType
 */
export interface CallTypeI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * Use to directly select call type (lowercase, no space)
   * label: Short Name
   */
  shortName?: string;

  headingTitle?: string;

  headingSubtitle?: string;

  headingInfo?: string;

  headingLogo?: string;

  selectTitle?: string;

  selectInstructions?: string;

  selectFooter?: string;

  /**
   * How does the user select the slot
   * label: Select Slot UI
   */
  selectSlotUI?: string;

  confirmTitle?: string;

  confirmEmail?: string;

  confirmName?: string;

  confirmPhone?: string;

  confirmInstructions?: string;

  confirmFooter?: string;

  confirmQuestion?: string;

  /**
   * Leave empty if no additional question
   * label: Additional Question 1 title
   */
  confirmAdditional1?: string;

  /**
   * Leave empty of free text otherwise options separated by |
   * label: Addl Question 1 options
   */
  confirmAdditional1Options?: string;

  /**
   * Leave empty if no additional question
   * label: Additional Question 2 title
   */
  confirmAdditional2?: string;

  /**
   * Leave empty of free text otherwise options separated by |
   * label: Addl Question 2 options
   */
  confirmAdditional2Options?: string;

  durationInMinutes?: number;

  userCanSelectRepresentative?: boolean;

  showRepresentativeNameInSlots?: boolean;

  selectRepByPriority?: boolean;

  /**
   * fk CallRepI
   */
  rep1?: CallRepI;

  /**
   * fk CallRepI key
   */
  rep1Id?: number|string;

  /**
   * fk CallRepI
   */
  rep2?: CallRepI;

  /**
   * fk CallRepI key
   */
  rep2Id?: number|string;

  /**
   * fk CallRepI
   */
  rep3?: CallRepI;

  /**
   * fk CallRepI key
   */
  rep3Id?: number|string;

  /**
   * fk CallRepI
   */
  rep4?: CallRepI;

  /**
   * fk CallRepI key
   */
  rep4Id?: number|string;

  onlyLocale?: string;

  timezoneOffsetFrom?: number;

  timezoneOffsetTo?: number;

} // CallType

/** CallType Definition */
export const CallTypeD = {
  recordTypeName: 'CallType',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  shortName: {
    n: 'shortName',
    t: 'string'
  },
  headingTitle: {
    n: 'headingTitle',
    t: 'string',
    d: 'Accorto Call'
  },
  headingSubtitle: {
    n: 'headingSubtitle',
    t: 'string',
    d: 'Pick a good time for you'
  },
  headingInfo: {
    n: 'headingInfo',
    t: 'string',
    d: 'Welcome to Accorto Time & Expense Support.\nPlease select a time slot convenient for you.\nLooking forward to helping you.'
  },
  headingLogo: {
    n: 'headingLogo',
    t: 'string'
  },
  selectTitle: {
    n: 'selectTitle',
    t: 'string',
    d: 'Please select a time slot'
  },
  selectInstructions: {
    n: 'selectInstructions',
    t: 'string',
    d: 'If you don"t find a good time for you, please send an email to info@accorto.com'
  },
  selectFooter: {
    n: 'selectFooter',
    t: 'string'
  },
  selectSlotUI: {
    n: 'selectSlotUI',
    t: 'string',
    d: 'Week'
  },
  confirmTitle: {
    n: 'confirmTitle',
    t: 'string',
    d: 'Please Confirm'
  },
  confirmEmail: {
    n: 'confirmEmail',
    t: 'string',
    d: 'Your Email'
  },
  confirmName: {
    n: 'confirmName',
    t: 'string',
    d: 'Your Name'
  },
  confirmPhone: {
    n: 'confirmPhone',
    t: 'string',
    d: 'Your Phone Number'
  },
  confirmInstructions: {
    n: 'confirmInstructions',
    t: 'string',
    d: 'You will receive an invite and email with the details and the GoToMeeting URL for the call. Looking forward talking to you!'
  },
  confirmFooter: {
    n: 'confirmFooter',
    t: 'string'
  },
  confirmQuestion: {
    n: 'confirmQuestion',
    t: 'string',
    d: 'What would you like to discuss/achieve in the call?'
  },
  confirmAdditional1: {
    n: 'confirmAdditional1',
    t: 'string'
  },
  confirmAdditional1Options: {
    n: 'confirmAdditional1Options',
    t: 'string'
  },
  confirmAdditional2: {
    n: 'confirmAdditional2',
    t: 'string'
  },
  confirmAdditional2Options: {
    n: 'confirmAdditional2Options',
    t: 'string'
  },
  durationInMinutes: {
    n: 'durationInMinutes',
    t: 'number'
  },
  userCanSelectRepresentative: {
    n: 'userCanSelectRepresentative',
    t: 'boolean'
  },
  showRepresentativeNameInSlots: {
    n: 'showRepresentativeNameInSlots',
    t: 'boolean'
  },
  selectRepByPriority: {
    n: 'selectRepByPriority',
    t: 'boolean'
  },
  rep1: {
    n: 'rep1',
    t: 'CallRepI'
  },
  rep1Id: {
    n: 'rep1Id',
    t: 'number|string'
  },
  rep2: {
    n: 'rep2',
    t: 'CallRepI'
  },
  rep2Id: {
    n: 'rep2Id',
    t: 'number|string'
  },
  rep3: {
    n: 'rep3',
    t: 'CallRepI'
  },
  rep3Id: {
    n: 'rep3Id',
    t: 'number|string'
  },
  rep4: {
    n: 'rep4',
    t: 'CallRepI'
  },
  rep4Id: {
    n: 'rep4Id',
    t: 'number|string'
  },
  onlyLocale: {
    n: 'onlyLocale',
    t: 'string'
  },
  timezoneOffsetFrom: {
    n: 'timezoneOffsetFrom',
    t: 'number'
  },
  timezoneOffsetTo: {
    n: 'timezoneOffsetTo',
    t: 'number'
  },
}; // CallType

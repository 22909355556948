import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {EffectsModule} from '@ngrx/effects';
import {ServiceWorkerModule} from '@angular/service-worker';
import {RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';

import {AccortoModule} from 'accorto';
import {environment} from '../environments/environment';
import {reducers} from './reducers';

import {AppComponent} from './app.component';
import {ConfirmComponent} from './confirm/confirm.component';
import {SelectComponent} from './select/select.component';
import {SelectService} from './select/select.service';
import {CallTypeEffects} from './call-type/call-type.effects';
import {SelectEffects} from './select/select.effects';
import {ManageComponent} from './manage/manage.component';
import {ErrorComponent} from './error/error.component';
import {ManageEffects} from './manage/manage.effects';
import {callTypeReducer} from './call-type/call-type.reducer';
import {selectReducer} from './select/select.reducer';
import {manageReducer} from './manage/manage.reducer';
import {WeekComponent} from './select/week/week.component';
import {DayComponent} from './select/day/day.component';

/**
 * Pick4D
 */
@NgModule({
  imports: [
    BrowserModule.withServerTransition({appId: 'p4d'}),
    HttpClientModule,
    ReactiveFormsModule,

    AppRoutingModule,

    AccortoModule.forRoot({
      server: environment.server,
      serverStage: environment.serverStage,
      production: environment.production,
      gaCode: 'UA-32129178-15',
      ts: environment.ts,
      productCode: 'Pick4D',
      productTitle: 'Pick 4D (Time)'
    }),

    StoreModule.forRoot(reducers, {
      runtimeChecks:
        {
          strictStateImmutability: true, strictActionImmutability: true,
          strictStateSerializability: false, strictActionSerializability: false
        }
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router', routerState: RouterState.Minimal }),
    StoreModule.forFeature('callType', callTypeReducer),
    StoreModule.forFeature('select', selectReducer),
    StoreModule.forFeature('manage', manageReducer),
    //
    EffectsModule.forRoot([ CallTypeEffects ]),
    EffectsModule.forFeature([ SelectEffects, ManageEffects ]),
    //
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    //
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    AppComponent,
    SelectComponent,
    ConfirmComponent,
    ManageComponent,
    ErrorComponent,
    WeekComponent,
    DayComponent
  ],
  providers: [
    SelectService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

import { CResponseCallType } from './c-response-call-type';
import { CRequestConfirm } from './c-request-confirm';
import { CRequestManage } from './c-request-manage';
import { SelectSlot } from './select-slot';
import { CResponseManage } from './c-response-manage';
import { Pick4dUtil } from './pick4d-util';
import { CResponseSelection } from './c-response-selection';
import { SelectDay } from './select-day';
import { CRequestSelection } from './c-request-selection';
import { AccortoCUtil } from 'accorto';
import { CResponseConfirm } from './c-response-confirm';

/* GenTsClass utility: Pick4dCUtil */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * Client Generated Utility for com.pick4d.domain
 */
export class Pick4dCUtil extends Pick4dUtil {


  /**
   * Convert json to CRequestConfirm
   * @return populated CRequestConfirm
   * @param json json info
   */
  static createCRequestConfirm(json: any): CRequestConfirm {
    const entity: CRequestConfirm = new CRequestConfirm();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'callTypeId') {
        entity.callTypeId = value;
      } else if (key === 'record') {
        entity.record = AccortoCUtil.createDataRecord(value); // cl
      } else if (key === 'slot') {
        entity.slot = this.createSelectSlot(value); // cl
      } else if (key === 'tzInfo') {
        entity.tzInfo = AccortoCUtil.createTzInfo(value); // cl
      } else if (key === 'callName') {
        entity.callName = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCRequestConfirm


  /**
   * Convert json to CRequestManage
   * @return populated CRequestManage
   * @param json json info
   */
  static createCRequestManage(json: any): CRequestManage {
    const entity: CRequestManage = new CRequestManage();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'callId') {
        entity.callId = value;
      } else if (key === 'callName') {
        entity.callName = value;
      } else if (key === 'cancelMessage') {
        entity.cancelMessage = value;
      } else if (key === 'record') {
        entity.record = AccortoCUtil.createDataRecord(value); // cl
      } else if (key === 'contactEmail') {
        entity.contactEmail = value;
      } else if (key === 'type') {
        entity.type = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCRequestManage


  /**
   * Convert json to CRequestSelection
   * @return populated CRequestSelection
   * @param json json info
   */
  static createCRequestSelection(json: any): CRequestSelection {
    const entity: CRequestSelection = new CRequestSelection();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'callTypeId') {
        entity.callTypeId = value;
      } else if (key === 'endTimeMs') {
        entity.endTimeMs = value;
      } else if (key === 'startTimeMs') {
        entity.startTimeMs = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'localeName') {
        entity.localeName = value;
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'params') {
        entity.params = {};
        for (const vv of Object.keys(value)) {
          entity.params[vv] = value[vv];
        }
      } else if (key === 'referrer') {
        entity.referrer = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'rid') {
        entity.rid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'timeMs') {
        entity.timeMs = value;
      } else if (key === 'timeString') {
        entity.timeString = value;
      } else if (key === 'timezoneName') {
        entity.timezoneName = value;
      } else if (key === 'timezoneOffsetMinutes') {
        entity.timezoneOffsetMinutes = value;
      }
    } // for loop
    return entity;
  } // createCRequestSelection


  /**
   * Convert json to CResponseCallType
   * @return populated CResponseCallType
   * @param json json info
   */
  static createCResponseCallType(json: any): CResponseCallType {
    const entity: CResponseCallType = new CResponseCallType();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'callType') {
        entity.callType = this.createCallType(value); // cl
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseCallType


  /**
   * Convert json to CResponseConfirm
   * @return populated CResponseConfirm
   * @param json json info
   */
  static createCResponseConfirm(json: any): CResponseConfirm {
    const entity: CResponseConfirm = new CResponseConfirm();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'call') {
        entity.call = this.createCall(value); // cl
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseConfirm


  /**
   * Convert json to CResponseManage
   * @return populated CResponseManage
   * @param json json info
   */
  static createCResponseManage(json: any): CResponseManage {
    const entity: CResponseManage = new CResponseManage();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'call') {
        entity.call = this.createCall(value); // cl
      } else if (key === 'callList') {
        entity.callList = [];
        for (const vv of value) {
          entity.callList.push(this.createCall(vv));
        }
      } else if (key === 'record') {
        entity.record = AccortoCUtil.createDataRecord(value); // cl
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseManage


  /**
   * Convert json to CResponseSelection
   * @return populated CResponseSelection
   * @param json json info
   */
  static createCResponseSelection(json: any): CResponseSelection {
    const entity: CResponseSelection = new CResponseSelection();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'callRepId') {
        entity.callRepId = value;
      } else if (key === 'callRepName') {
        entity.callRepName = value;
      } else if (key === 'callTypeId') {
        entity.callTypeId = value;
      } else if (key === 'firstMs') {
        entity.firstMs = value;
      } else if (key === 'lastMs') {
        entity.lastMs = value;
      } else if (key === 'days') {
        entity.days = value;
      } else if (key === 'slots') {
        entity.slots = [];
        for (const vv of value) {
          entity.slots.push(this.createSelectSlot(vv));
        }
      } else if (key === 'category') {
        entity.category = value;
      } else if (key === 'debugInfo') {
        entity.debugInfo = value;
      } else if (key === 'error') {
        entity.error = value;
      } else if (key === 'isLoggedOut') {
        entity.isLoggedOut = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'rid') {
        entity.rid = value;
      }
    } // for loop
    return entity;
  } // createCResponseSelection


  /**
   * Convert json to SelectDay
   * @return populated SelectDay
   * @param json json info
   */
  static createSelectDay(json: any): SelectDay {
    const entity: SelectDay = new SelectDay();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'label') {
        entity.label = value;
      } else if (key === 'slots') {
        entity.slots = [];
        for (const vv of value) {
          entity.slots.push(this.createSelectSlot(vv));
        }
      }
    } // for loop
    return entity;
  } // createSelectDay


  /**
   * Convert json to SelectSlot
   * @return populated SelectSlot
   * @param json json info
   */
  static createSelectSlot(json: any): SelectSlot {
    const entity: SelectSlot = new SelectSlot();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'callRepId') {
        entity.callRepId = value;
      } else if (key === 'callRepName') {
        entity.callRepName = value;
      } else if (key === 'dateLabel') {
        entity.dateLabel = value;
      } else if (key === 'isoDateTime') {
        entity.isoDateTime = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'minutes') {
        entity.minutes = value;
      } else if (key === 'slotName') {
        entity.slotName = value;
      } else if (key === 'startMs') {
        entity.startMs = value;
      } else if (key === 'tabIndex') {
        entity.tabIndex = value;
      } else if (key === 'timeLabel') {
        entity.timeLabel = value;
      } else if (key === 'utcMs') {
        entity.utcMs = value;
      } else if (key === 'isNightTime') {
        entity.isNightTime = value;
      }
    } // for loop
    return entity;
  } // createSelectSlot

} // Pick4dCUtil

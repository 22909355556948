import {Component, OnDestroy, OnInit} from '@angular/core';
import {SelectSlot} from "../../model/select-slot";
import {selectRequest, selectUserSlot} from "../select.actions";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {Logger, selectTimezone, TzInfo, TzUtil} from "accorto";
import {selectCallType} from "../../call-type/call-type.selectors";
import {Subscription} from "rxjs";
import {CallType} from "../../model/call-type";
import {selectSelectSlots, selectSelectStatus} from "../select.selectors";

@Component({
  selector: 'p4d-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss']
})
export class DayComponent implements OnInit, OnDestroy {

  /** The Date */
  selectedDate: Date = new Date();
  selectedDateString: string = '';

  /** Enabled Days */
  enabledDays: Set<number> = new Set<number>();

  /** Day Slots */
  daySlots: SelectSlot[] = [];

  /** Busy loading */
  busy = true;
  /** Status */
  selectStatus: string = '-';

  private readonly ONEDAY: number = 24 * 60 * 60 * 1000;

  /** Call Type */
  private callType?: CallType;
  /** TzInfo */
  private tzInfo?: TzInfo;
  /** Select (all) Slots */
  private slots: SelectSlot[] = [];

  private log: Logger = new Logger('SelectDay');
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>) {
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit(): void {
    // call type
    this.subscriptions.push(
      this.store.pipe(select(selectCallType))
        .subscribe((callType) => {
          if (callType) {
            // this.log.debug('ngOnInit - callType', callType)();
            this.callType = callType;
            //
            if (this.callType.id) {
              this.store.dispatch(selectRequest({
                callTypeId: this.callType.id,
                startTimeMs: new Date().getTime(),
                endTimeMs: -1
              }));
            }
          }
        })
    );

    // time zone
    this.subscriptions.push(
      this.store.pipe(select(selectTimezone))
        .subscribe((tzInfo) => {
          if (tzInfo) {
            // this.log.debug('ngOnInit - tzInfo', tzInfo)();
            this.tzInfo = tzInfo;
            this.buildDay();
          }
        })
    );

    // status (busy)
    this.subscriptions.push(
      this.store.pipe(select(selectSelectStatus))
        .subscribe((state) => {
          // this.log.debug('ngOnInit - state', state)();
          this.busy = !state || state === 'requested';
          if (state) {
            this.selectStatus = state;
          }
        })
    );

    this.subscriptions.push(
      this.store.pipe(select(selectSelectSlots))
        .subscribe((slots: SelectSlot[]) => {
          // this.log.debug('ngOnInit - slotList', slots)();
          if (slots) {
            // this.slots = slots; // read-only
            this.slots = [];
            slots.forEach((slot) => {
              const cc = new SelectSlot();
              Object.assign(cc, slot);
              this.slots.push(cc);
            });
            this.buildDay();
          } // slots
        }) // subscribe
    );

  } // ngOnInit


  onDateSelected(utcDate: Date): void {
    this.log.debug('onDateSelected', utcDate ? utcDate.toISOString() : utcDate)();
    if (utcDate) {
      this.selectedDate = utcDate;
      this.buildDay();
    }
  }

  onSelectSlot(slot: SelectSlot): void {
    this.store.dispatch(selectUserSlot({slot}));
  }

  isNightTime(slot: SelectSlot): boolean {
    if (slot.slotName) {
      return slot.slotName > '18:00' || slot.slotName < '07:00';
    }
    return false;
  }

  /**
   * Build Day
   */
  private buildDay(): void {
    if (!this.tzInfo || !this.callType) {
      this.log.debug('buildDay --', this.tzInfo, this.callType)();
      return;
    }

    const slotStartSet = new Set<string>();
    let firstDate: Date | undefined;
    let tabindex = 10;
    const enabledDays = new Set<number>();
    for (const slot of this.slots) {
      const date = new Date(slot.startMs); // utc

      const tzDate = TzUtil.getTimezoneUtcDate(this.tzInfo, date);
      enabledDays.add(tzDate.getTime());
      slot.utcMs = tzDate.getTime();
      if (firstDate == null || firstDate.getTime() > slot.utcMs) {
        firstDate = tzDate;
      }
      slot.dateLabel = TzUtil.formatDdMm(this.tzInfo, date); // date

      slot.label = TzUtil.formatHhMm(this.tzInfo, date); // time
      slot.slotName = TzUtil.formatHhMm24(this.tzInfo, date);
      slotStartSet.add(slot.slotName);
      slot.tabIndex = tabindex++;
      slot.isNightTime = this.isNightTime(slot);
    }
    // set selected date to first date
    if (firstDate && (this.selectedDate.getUTCMilliseconds() !== 0 || this.selectedDate.getUTCSeconds() != 0)) {
      this.selectedDate = firstDate;
    }
    this.selectedDateString = TzUtil.formatDdMm(this.tzInfo,
      new Date(this.selectedDate.getUTCFullYear(), this.selectedDate.getUTCMonth(), this.selectedDate.getUTCDate()));

    // day
    const fromTime = this.selectedDate.getTime();
    this.daySlots = [];

    let delta: number = 100;
    let next: number | undefined;
    for (const slot of this.slots) {
      if (slot.utcMs === fromTime) {
        const slotName = slot.slotName;
        if (slotName) {
          const hhNum = Number(slotName.substring(0, 2) + slotName.substring(3));
          if (next) {
            const delta2 = hhNum - next;
            if (delta2 > 0) {
              console.log('-->', slot.slotName, delta2);
              if (delta2 >= delta) {
                this.daySlots.push(new SelectSlot());
              }
            } else if (delta2 < 0) {
              console.log('--<', slot.slotName, delta2);
            }
          }
          next = hhNum + delta;
        }
        this.daySlots.push(slot);
      }
    }
    this.enabledDays = new Set<number>(enabledDays); // force change detection
    this.log.info('buildDay', this.selectedDate.toISOString(),
      'slots=' + this.daySlots.length, 'days=' + this.enabledDays.size)();
  } // buildDay

} // DayComponent

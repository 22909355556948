import { Account } from 'accorto';
import { TenantUser } from 'accorto';
import { Tenant } from 'accorto';
import { CallRep } from './call-rep';
import { CallType } from './call-type';
import { DataRecordI } from 'accorto';
import { DataRecord } from 'accorto';
import { CallI, CallD } from './call-i';
import { DataRecordF } from 'accorto';
import { Contact } from 'accorto';

/* GenTsClass domain: Call */
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Call
 */
export class Call {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id?: number|string; // optional=true

  /**
   * label: Name
   */
  public name: string = ''; // default

  /**
   * label: Label
   */
  public label?: string; // optional=true

  /**
   * label: Description
   */
  public description?: string; // optional=true

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created?: number; // optional=true

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy?: TenantUser; // optional=true

  public createdById?: number; // optional=true

  /**
   * label: Updated Time
   */
  public updated?: number; // optional=true

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy?: TenantUser; // optional=true

  public updatedById?: number; // optional=true

  /**
   * label: External Id
   */
  public externalId?: string; // optional=true

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant?: Tenant; // optional=true

  public tenantId?: number; // optional=true

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner?: TenantUser; // optional=true

  public ownerId?: number; // optional=true

  /**
   * label: Record Version
   */
  public version?: number; // optional=true

  /**
   * label: Salesforce Id
   */
  public sfId?: string; // optional=true

  /**
   * parent fk CallType
   * label: Call Type
   */
  public callType?: CallType; // optional=true

  public callTypeId?: number; // optional=true

  /**
   * fk CallRep
   * label: Call Rep
   */
  public callRep?: CallRep; // optional=true

  public callRepId?: number; // optional=true

  /**
   * label: Call Date
   */
  public callDate?: number; // optional=true

  /**
   * label: Call Date/Time
   */
  public callDateTime?: number; // optional=true

  /**
   * Minutes
   * label: Duration (min)
   */
  public callMinutes?: number; // optional=true

  /**
   * label: Call Time
   */
  public callTime?: number; // optional=true

  /**
   * label: Call Timezone
   */
  public callTimezone?: string; // optional=true

  /**
   * label: Help
   */
  public help?: string; // optional=true

  /**
   * label: Call Instructions
   */
  public instructions?: string; // optional=true

  /**
   * label: History
   */
  public history: string[] = [];

  /**
   * label: Call Instructions (html)
   */
  public instructionsHtml?: string; // optional=true

  /**
   * label: Locale
   */
  public localeName?: string; // optional=true

  /**
   * label: Contact Name
   */
  public contactName?: string; // optional=true

  /**
   * label: Contact Email
   */
  public contactEmail?: string; // optional=true

  /**
   * label: Contact Phone
   */
  public contactPhone?: string; // optional=true

  /**
   * label: Contact Answer
   */
  public contactAnswer?: string; // optional=true

  /**
   * label: Contact Timezone
   */
  public contactTimezone?: string; // optional=true

  /**
   * label: Contact Additional (1)
   */
  public contactAdditional1?: string; // optional=true

  /**
   * label: Contact Additional (2)
   */
  public contactAdditional2?: string; // optional=true

  /**
   * label: Contact Infos
   */
  public contactInfos: {[key: string]: string|undefined|null} = {};

  /**
   * label: Parameters
   */
  public params: {[key: string]: string|undefined|null} = {};

  /**
   * label: Add to Calendar
   */
  public addToCalendar: {[key: string]: string|undefined|null} = {};

  /**
   * label: Status
   */
  public status?: string; // optional=true

  /**
   * label: Manage Url
   */
  public manageUrl?: string; // optional=true

  /**
   * label: Call Join Url
   */
  public callJoinUrl?: string; // optional=true

  /**
   * label: Call Join Info
   */
  public callJoinInfo?: string; // optional=true

  /**
   * label: Calendar Event Id
   */
  public calendarEventId?: string; // optional=true

  /**
   * label: Calendar Event Link
   */
  public calendarEventLink?: string; // optional=true

  /**
   * label: Calendar ICalUID
   */
  public calendarICalUID?: string; // optional=true

  /**
   * label: Cancel Message
   */
  public cancelMessage?: string; // optional=true

  /**
   * label: GTM Meeting Id
   */
  public gtmMeetingId?: string; // optional=true

  /**
   * label: Notes
   */
  public notes?: string; // optional=true

  /**
   * label: Invite Others
   */
  public inviteOthers?: string; // optional=true

  /**
   * fk Account
   * label: Account
   */
  public account?: Account; // optional=true

  public accountId?: number; // optional=true

  /**
   * label: Account
   */
  public accountSfId?: string; // optional=true

  /**
   * fk Contact
   * label: Contact
   */
  public contact?: Contact; // optional=true

  public contactId?: number; // optional=true

  /**
   * label: Contact
   */
  public contactSfId?: string; // optional=true


  /**
   * @return Call record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'Call';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key: string) => {
    // @ts-ignore
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): Call {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        // @ts-ignore
        const value = record.valueMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, CallD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        // @ts-ignore
        const value = record.changeMap[ key ];
        // @ts-ignore
        this[ key ] = DataRecordF.recordValue(key, value, CallD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: CallI): Call {
    Object.keys(CallD).forEach((key: string) => {
      // @ts-ignore
      const value = record[ key ];
      if (value) {
        // @ts-ignore
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'callRep') { // fk
          this.callRep = new CallRep();
          this.callRep.setFromI(value);
        } else if (key === 'account') { // fk
          this.account = new Account();
          this.account.setFromI(value);
        } else if (key === 'contact') { // fk
          this.contact = new Contact();
          this.contact.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // Call

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {Action, Store} from '@ngrx/store';
import {EMPTY} from 'rxjs';
import {selectError, selectRefresh, selectRequest, selectResponse} from './select.actions';
import {AppState} from '../reducers';
import {SelectService} from './select.service';
import {NotificationService} from 'accorto';
import {CallTypeState} from '../call-type/call-type.reducer';
import {SelectState} from './select.reducer';

@Injectable()
export class SelectEffects {

  selectRequest$ = createEffect(() => this.actions$.pipe(
    ofType(selectRequest),
    mergeMap((action) => this.service.get(action.callTypeId, action.startTimeMs, action.endTimeMs)
      .pipe(
        catchError(err => {
          console.log('selectRequest$.error', err); // TypeError: Cannot read property 'callType' of null
          this.store.dispatch(selectError({err}));
          return EMPTY;
        })
      )
    ),
    tap((selection) => {
      if (selection.error) {
        console.log('selectRequest$.response', selection);
        this.notify.addError(selection.error, selection.message);
      }
    }),
    map(selection => selectResponse({ selection }))
    ), { useEffectsErrorHandler: true }
  );


  selectRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(selectRefresh),
    withLatestFrom(this.store),
    map(([ action, storeState ]: [ Action, AppState ]) => {
      const callTypeString = 'callType';
      // @ts-ignore
      const callTypeState: CallTypeState | undefined = storeState[callTypeString] as CallTypeState;
      const callTypeId: number | string = callTypeState?.callType?.id ?? 0;
      const selectString = 'select';
      // @ts-ignore
      const selectState: SelectState | undefined = storeState[selectString] as SelectState;
      const startTimeMs: number = selectState?.startTimeMs ?? 0;
      // console.debug('selectRefresh$', callTypeId, startTimeMs);
      this.store.dispatch(selectRequest({callTypeId, startTimeMs, endTimeMs: 0}));
    })
  ), { dispatch: false });


  selectError$ = createEffect(() => this.actions$.pipe(
    ofType(selectError),
    tap((action) => {
      console.warn('selectError$', action);
      this.notify.addException('Connection Error (S)', action.err);
    })
    ), { dispatch: false }
  );

  constructor(private actions$: Actions,
              private service: SelectService,
              private store: Store<AppState>,
              private notify: NotificationService) {
  }
}

import {TzInfo} from '../model/tz-info';
import {AccortoService} from '../accorto.service';

/**
 * Date-Time with Timezone
 */
export class TzUtil {

  /**
   * Format timezone
   * @param tzInfo timezone info
   * @param dd the date
   * @param locale locale
   * @return weekday-day-month
   */
  public static formatDdMm(tzInfo: TzInfo, dd: Date, locale: string = AccortoService.locale): string {
    const dayOptions: Intl.DateTimeFormatOptions = {
      timeZone: tzInfo.id,
      weekday: 'short', day: 'numeric', month: 'short'
    };
    try {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString#using_options
      return dd.toLocaleString(locale, dayOptions);
    } catch (e) {
      console.debug('TzUtil.formatDdMm', e);
    }
    return this.formatDdMmIe(tzInfo, dd, locale);
  }

  /**
   * IE Fallback
   * @param tzInfo timezone info
   * @param dd the date
   * @param locale locale
   * @return weekday-day-month
   */
  public static formatDdMmIe(tzInfo: TzInfo, dd: Date, locale: string): string {
    const dayOptions: Intl.DateTimeFormatOptions = {
      weekday: 'short', day: 'numeric', month: 'short'
    };
    const time = dd.getTime() + (tzInfo.offsetSeconds * 1000) + (dd.getTimezoneOffset() * 60 * 1000);
    const dd2 = new Date(time);
    return dd2.toLocaleString(locale, dayOptions);
  }


  /**
   * Get UTC date in Timezone
   * @param tzInfo timezone info
   * @param dd the utc date
   * @param locale locale
   * @return utc date in timezone
   */
  public static getTimezoneUtcDate(tzInfo: TzInfo, dd: Date, locale: string = AccortoService.locale): Date {
    const dayOptions: Intl.DateTimeFormatOptions = {
      timeZone: tzInfo.id,
      day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric'
    };
    const parts: Intl.DateTimeFormatPart[] = new Intl.DateTimeFormat(locale, dayOptions)
      .formatToParts(dd);
    let day = -1;
    let month = -1;
    let year = -1;
    for (const part of parts) {
      if (part.type === 'year') {
        year = Number(part.value);
      } else if (part.type === 'month') {
        month = Number(part.value) - 1;
      } else if (part.type === 'day') {
        day = Number(part.value);
      }
    }
    const date = new Date(Date.UTC(year, month, day));
    // console.log(tzInfo.id, parts, date.toISOString());
    return date;
  } // getTimezoneUtcDate

  /**
   * Format timezone date
   * @param tzInfo timezone info
   * @param dd the date
   * @param locale locale
   * @return hour-minute timezone
   */
  public static formatHhMm(tzInfo: TzInfo, dd: Date, locale: string = AccortoService.locale): string {
    const slotOptions: Intl.DateTimeFormatOptions = {
      timeZone: tzInfo.id,
      hour: 'numeric', minute: '2-digit', timeZoneName: 'short'
    };
    try {
      return dd.toLocaleString(locale, slotOptions);
    } catch (e) {
    }
    return this.formatHhMmIe(tzInfo, dd, locale);
  }

  /**
   * IE fallback
   * @param tzInfo timezone info
   * @param dd the date
   * @param locale locale
   * @return hour-minute timezone
   */
  public static formatHhMmIe(tzInfo: TzInfo, dd: Date, locale: string): string {
    const slotOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric', minute: '2-digit'
    };
    const time = dd.getTime() + (tzInfo.offsetSeconds * 1000) + (dd.getTimezoneOffset() * 60 * 1000);
    const dd2 = new Date(time);
    return dd2.toLocaleString(locale, slotOptions) + ' ' + tzInfo.shortName;
  }


  /**
   * Format timezone date
   * @param tzInfo timezone info
   * @param dd the date
   * @param locale locale
   * @return 24hour:minute in timezone 13:00
   */
  public static formatHhMm24(tzInfo: TzInfo, dd: Date, locale: string = AccortoService.locale): string {
    try {
      const slotSortOptions: Intl.DateTimeFormatOptions = {
        timeZone: tzInfo.id,
        hour: '2-digit', minute: '2-digit'
      };
      const formatted = dd.toLocaleString(locale, slotSortOptions);
      //
      const am = formatted.includes('AM');
      const pm = formatted.includes('PM');

      const parts: string[] = formatted.split(/[: ]/);
      // console.debug(formatted, parts);

      let hh = Number(parts[ 0 ]);
      if (am && hh === 12) {
        hh = 0;
      } else if (pm && hh !== 12) {
        hh += 12;
      }
      let retValue = (hh < 10 ? '0' : '');
      retValue += hh;
      retValue += ':' + parts[ 1 ];
      if (!retValue.includes('NaN')) {
        return retValue;
      }
    } catch (e) {
    }
    return this.formatHhMm24Ie(tzInfo, dd, locale);
  }

  /**
   * IE fallback
   * @param tzInfo timezone info
   * @param dd the date
   * @param locale locale
   * @return 24hour:minute in timezone 13:00
   */
  public static formatHhMm24Ie(tzInfo: TzInfo, dd: Date, locale: string): string {
    const time = dd.getTime() + (tzInfo.offsetSeconds * 1000) + (dd.getTimezoneOffset() * 60 * 1000);
    const dd2 = new Date(time);
    //
    const hh = dd2.getHours();
    let retValue = (hh < 10 ? '0' : '');
    retValue += hh + ':';
    const mm = dd2.getMinutes();
    retValue += (mm < 10 ? '0' : '');
    retValue += mm;
    return retValue;
  }


  /**
   * Format timezone date
   * @param tzInfo timezone info
   * @param dd the date
   * @param locale locale
   * @return weekday-day-month hour-minute timezone
   */
  public static format(tzInfo: TzInfo | undefined, dd: Date, locale: string = AccortoService.locale): string {
    const tzId = tzInfo ? tzInfo.id : AccortoService.timezoneId;
    const slotOptions: Intl.DateTimeFormatOptions = {
      timeZone: tzId, timeZoneName: 'long',
      weekday: 'long', day: 'numeric', month: 'long',
      // year: 'numeric',
      hour: 'numeric', minute: 'numeric'
    };
    try {
      return dd.toLocaleString(locale, slotOptions);
    } catch (e) {
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.formatIe(tzInfo!, dd, locale);
  }

  /**
   * IE fallback
   * @param tzInfo timezone info
   * @param dd the date
   * @param locale locale
   * @return weekday-day-month hour-minute timezone
   */
  public static formatIe(tzInfo: TzInfo, dd: Date, locale: string): string {
    const slotOptions: Intl.DateTimeFormatOptions = {
      timeZone: 'UTC',
      weekday: 'long', day: 'numeric', month: 'long',
      // year: 'numeric',
      hour: 'numeric', minute: 'numeric'
    };
    const offset = tzInfo ? tzInfo.offsetSeconds * 1000 : 0;
    const time = dd.getTime() + offset; // + (dd.getTimezoneOffset() * 60 * 1000);
    const dd2 = new Date(time);
    return dd2.toLocaleString(locale, slotOptions) + ' ' + tzInfo.fullName;
  }

} // TzUtil

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CallTypeState} from './call-type.reducer';
import {CallType} from '../model/call-type';

// feature selector
// export const selectCallTypeState = state => state.callType;
export const selectCallTypeState = createFeatureSelector<CallTypeState>('callType');


export const selectCallType = createSelector(
  selectCallTypeState,
  (callTypeState): CallType | undefined => callTypeState.callType
);

export const selectCallTypeId = createSelector(
  selectCallType,
  (callType): number | string | undefined => callType?.id
);


export const selectCallTypeHeadingTitle = createSelector(
  selectCallType,
  (callType): string | undefined => callType?.headingTitle
);


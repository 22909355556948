import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {NotificationService, uiRequestAction} from 'accorto';
import {AppState} from '../reducers';
import {SelectSlot} from '../model/select-slot';
import {selectSelectSlot} from '../select/select.selectors';
import {selectUserSlot} from '../select/select.actions';

@Injectable({
  providedIn: 'root'
})
export class ConfirmGuard implements CanActivate {

  private slot?: SelectSlot;
  private slots: SelectSlot[] = [];

  constructor(private service: NotificationService,
              private router: Router,
              private store: Store<AppState>) {

    /* test fails */
    store.pipe(select(selectSelectSlot)).subscribe((slot) => {
      this.slot = slot;
    });
    /*
    store.pipe(select(selectSelectSlots)).subscribe((slots) => {
      this.slots = slots;
    });
    /**/
  }

  /**
   * Is there something to confirm ?
   * @param route route snapshot
   * @param state new state
   */
  public canActivate(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.slot) {
      return true;
    }
    console.log('ConfirmGuard from ' + this.router.url, state.url, route.params);
    if (route.params.ts) {
      // search for ts
      if (this.slots) { // need to wait
        const ts = Number(route.params.ts);
        console.log('ConfirmGuard ' + ts + ' #' + this.slots.length);
        for (const slot of this.slots) {
          if (slot.startMs === ts) {
            this.store.dispatch(selectUserSlot({ slot }));
            this.store.dispatch(uiRequestAction({ uiName: 'Call' }));
            return true;
          }
        }
      }
    }
    //
    this.service.addWarning('Select a Slot first',
      'Before confirming, please pick a Time Slot.',
      undefined, 8);

    if (this.router.url !== '/select') { // current url
      this.router.navigate([ '/select' ]);
    }
    return false;
  } // canActivate

} // ConfirmGuard

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ManageState} from './manage.reducer';

export const selectManageState = createFeatureSelector<ManageState>('manage');


export const selectManageCall = createSelector(
  selectManageState,
  manageState => manageState.call
);
